import React from "react";
import Slider from "react-slick";
import { db, logEvent } from "../firebase/firebase.js";
import { withRouter } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import Helmet from "react-helmet";
import classnames from "classnames";

import MainNavbar from "components/Navbars/MainNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import Ingredients from "components/Ingredients.js";
import ContactUsBanner from "components/HomePage/ContactUsBanner.js";
import FaqComponent from "components/FaqComponent.js";
import ScreenCapture from "components/HomePage/ScreenCapture.js";

import {
  Badge,
  Button,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AddMenuFavorite from "components/AddMenuFavorite.js";
import UserContext from "contexts/UserContext.js";

const settings = {
  centerMode: true,
  centerPadding: "40px",
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        initialSlide: 2,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
};

const VIEW_LIST = "viewList";
const ADD_LIST = "addList";
const ADD_CART = "addCart";
const SAVE = "save";

class MenuPage extends React.Component {
  state = {
    mealRecipes: [],
    menuTags: [],
    ingredients: [],
    tabs: 0,
    shoppingList: [],
    mealName: "",
    loading: true,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    if (this.state.tabs === index) {
      this.setState({
        [state]: 0,
      });
    } else {
      this.setState({
        [state]: index,
      });
    }
  };
  // constructor(props){
  //   super(props);
  //   enablePersistance();
  // }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    //todo fix refresh shows ui even loggedin

    this.addToList = this.addToList.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.viewList = this.viewList.bind(this);
    var that = this;
    let mealRecipes = [...this.state.mealRecipes];
    let menuTags = [...this.state.menuTags];
    let ingredients = [...this.state.ingredients];
    var shoppingList = [...this.state.shoppingList];

    ///BE CAREFUL ADDING RECIPES AND RECIPE TAGS
    db.collection("menus")
      .doc(this.props.match.params.id)
      .get()
      .then((doc) => {
        that.setState({ mealName: doc.data().displayName });
        // that.setState({ shoppingList: doc.data().shoppingList });
        doc.data().recipes.forEach(function (ref) {
          ref.onSnapshot(function (doc) {
            let tempRecipe = {
              img: doc.data().thumbPicUrl,
              name: doc.data().displayName,
              id: doc.id,
              tags: doc.data().tags,
              calories: doc.data().calories,
              duration: doc.data().duration,
              serving: doc.data().serving,
            };
            let id = doc.id;
            mealRecipes.push(tempRecipe);
            that.setState({ mealRecipes });
            doc.data().tags.forEach(function (ref) {
              ref.onSnapshot(function (doc) {
                menuTags.push({ id: id, name: doc.data().displayName });
                that.setState({ menuTags });
              });
            });
          });
        });
      });

    db.collection("menus")
      .doc(this.props.match.params.id)
      .collection("ingredients")
      .orderBy("createdDate", "asc")
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {
          let tempIngredients = {
            displayName: change.doc.data().displayName,
            isPantry: change.doc.data().isPantry,
            amount: change.doc.data().amount,
            uom: change.doc.data().uom,
            picUrl: change.doc.data().imgUrl,
          };
          if (change.type === "added") {
            if (!change.doc.data().isPantry) {
              var shoppingListItem =
                change.doc.data().amount.toString() +
                " " +
                change.doc.data().uom +
                " " +
                change.doc.data().displayName;
              shoppingList.push(shoppingListItem);
              that.setState({ shoppingList });
            }
            ingredients.push(tempIngredients);
            that.setState({ ingredients });
          }
          if (change.type === "modified") {
          }
          if (change.type === "removed") {
          }
        });
        that.setState({ loading: false });
        if (that.props.match.params.action) {
          if (that.props.match.params.action === VIEW_LIST) {
            that.viewList();
          } else if (that.props.match.params.action === ADD_LIST) {
            that.addToList();
          } else if (that.props.match.params.action === ADD_CART) {
            that.addToBasket();
          }
        }

        if (that.props.match.params.action === SAVE) {
          if (
            that.context.userFavorites &&
            that.context.userFavorites.includes(that.props.match.params.id)
          ) {
          } else {
            that.context.updateFavs(that.props.match.params.id, true);
          }
        }
      });
  }

  /*global whisk*/
  addToList = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      var that = this;
      whisk.queue.push(function () {
        whisk.shoppingList.addProductsToList({
          products: that.state.shoppingList,
        });
      });
      logEvent("addedToList", { name: this.props.match.params.id });
    } else {
      logEvent("noSubAddedToList", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/addList`);
    }
  };

  signUpNow = () => {
    logEvent("SignInitiatedFromMenu", { name: this.props.match.params.id });
    const { history } = this.props;
    history.push(`/sign-up/${this.props.match.params.id}/signupFromMenu`);
  };

  viewList = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      whisk.queue.push(function () {
        whisk.shoppingList.viewList({
          styles: {
            type: "modal",
          },
        });
      });
      logEvent("viewedList", { name: this.props.match.params.id });
    } else {
      logEvent("noSubViewedList", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/viewList`);
    }
  };

  addToBasket = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      var that = this;
      whisk.queue.push(function () {
        whisk.shoppingList.addProductsToBasket({
          products: that.state.shoppingList,
        });
      });
      logEvent("addedToCart", { name: this.props.match.params.id });
    } else {
      logEvent("noSubAddedToCart", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/addCart`);
    }
  };

  render() {
    //FIREBASE ONE TAP SIGN UP
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    // if (!isLoggedIn) {
    //   if(document.getElementById("firebaseui")){
    //     startFirebaseUI("#firebaseui");
    //   }
    // }
    // else if(isLoggedIn){
    //   resetFirebaseUI();
    // }
    var unlock = false;
    var blurContent = "blur-content";
    if (this.context.paidUser) {
      unlock = true;
      blurContent = "";
    }
    return (
      <>
        <Helmet>
          {this.state.mealName ? (
            <title>{this.state.mealName + " | Popdega"}</title>
          ) : (
            <title>{"Chef designed meal plans | Popdega"}</title>
          )}
          <script
            src="https://accounts.google.com/gsi/client"
            async
            defer
          ></script>
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <section>
            <Container>
              {/* <div className="row  mt-4">
                <div className="text-center menu-page-title ">
                  <h1 >{this.state.mealName}</h1>
                </div>
                <div className="ml-auto mr-auto mb-1 div-inlineBlock ">
                <AddMenuFavorite menuId={this.props.match.params.id} />
                </div>
              </div> */}

              <Row className="menu-page-titleRow mt-3">
                <div className="menu-page-titleContainer">
                  <h1 className="menu-page-title">{this.state.mealName}</h1>
                </div>
                <div className="menu-page-favButton">
                  <AddMenuFavorite menuId={this.props.match.params.id} />
                </div>
              </Row>
            </Container>
          </section>
          <section className="menu-page">
            <div>
              <Row className="row-grid px-2">
                <Col lg="12">
                  <Slider
                    ref={(slider) => (this.slider = slider)}
                    {...settings}
                    className="border-0 "
                  >
                    {this.state.mealRecipes.map((item) => {
                      return (
                        <Col md="12" className="h-100">
                          <Link to={`/recipes/${item.name}/${item.id}`}>
                            <div className="card card-lift--hover-sm mt-2 mb-5">
                              <img
                                className="card-img-top "
                                src={item.img}
                                alt={item.name}
                              />
                              <div className="card-body menu-page-recipeContainer shadow card-img-bottom menu-page-cardBody">
                                <Container>
                                  <div className="row menu-page-textContainer justify-content-center text-align-center">
                                    <h6 className="card-text menu-page-recipeText">
                                      {item.name}
                                    </h6>
                                  </div>
                                </Container>
                                <hr
                                  style={{
                                    marginTop: "0.5rem",
                                    marginBottom: "0.5rem",
                                  }}
                                />
                                <Container>
                                  <div className="row align-middle">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4  col-4 menu-page-labels">
                                      <p className="h7">
                                        <i className="fa fa-clock-o pr-1" />
                                        {item.duration} mins
                                      </p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4  col-4 menu-page-labels">
                                      <p className="h7">
                                        <i className="fa fa-cutlery pr-1" />
                                        {item.serving} servings
                                      </p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4  col-4 menu-page-labels">
                                      <p className="h7">
                                        <i className="fa fa-leaf pr-1" />{" "}
                                        {item.calories} cal
                                      </p>
                                    </div>
                                  </div>
                                </Container>

                                <hr
                                  style={{
                                    marginTop: "0.5rem",
                                    marginBottom: "0.5rem",
                                  }}
                                />
                                <Container className="align-self-center d-flex mt-2 ">
                                  <div col="row">
                                    <h6>
                                      {this.state.menuTags.map((tag) =>
                                        tag.id === item.id ? (
                                          <Badge
                                            color="secondary"
                                            pill
                                            id={tag}
                                            className="ml-2"
                                          >
                                            {tag.name}
                                          </Badge>
                                        ) : null
                                      )}
                                    </h6>
                                  </div>
                                </Container>
                              </div>
                            </div>
                          </Link>
                          {/* </a> */}
                        </Col>
                      );
                    })}
                  </Slider>
                </Col>
              </Row>
            </div>
          </section>
          <section>
            <Container className="mb-3">
              <div className="row">
                <div className="col-12">
                  <div className="nav-wrapper">
                    <Nav
                      className="nav-fill flex-column flex-md-row"
                      id="tabs-icons-text"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          aria-selected={this.state.tabs === 1}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.tabs === 1,
                          })}
                          onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                          href="#"
                          role="tab"
                        >
                          Ingredients
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-selected={this.state.tabs === 2}
                          className={classnames("mb-sm-3 mb-md-0 ", {
                            active: this.state.tabs === 2,
                          })}
                          onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                          href="#"
                          role="tab"
                        >
                          Pantry Items
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <Card className="shadow" hidden={this.state.tabs === 0}>
                    <CardBody >
                      <TabContent activeTab={"tabs" + this.state.tabs}>
                        <TabPane tabId="tabs1">
                          {/* <div className={`row ${blurContent}`}> */}
                          <div className="row">
                            {this.state.ingredients.map((item) => {
                              if (!item.isPantry) {
                                return (
                                  <Ingredients
                                    displayName={item.displayName}
                                    picUrl={item.picUrl}
                                    amount={item.amount}
                                    uom={item.uom}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                            {/* {unlock ? null : this.state.loading ? null : (
                              <Button
                                color="theme"
                                size="lg"
                                className="btn-icon btn-3 button-overlay"
                                // href={`/sign-up/${this.props.match.params.id}/signupFromMenu`}
                                onClick={() => this.signUpNow()}
                              >
                                <span className="btn-inner--icon">
                                  <i className="ni ni-lock-circle-open" />
                                </span>
                                Subscribe Now
                              </Button>
                            )} */}
                          </div>
                        </TabPane>
                        <TabPane tabId="tabs2">
                          <div className="row">
                            {/* <div className={`row ${blurContent}`}> */}
                            <div className="container">
                              <div className="row">
                                <div className="col-12">
                                  <div className="text-muted text-center mb-3">
                                    <p>
                                      Pantry items will not be automatically
                                      added.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.ingredients.map((item) => {
                              if (item.isPantry) {
                                return (
                                  <Ingredients
                                    displayName={item.displayName}
                                    picUrl={item.picUrl}
                                    amount={item.amount}
                                    uom={item.uom}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                            {/* {unlock ? null : this.state.loading ? null : (
                              <Button
                                color="theme"
                                size="lg"
                                className="btn-icon btn-3 button-overlay"
                                // href={`/sign-up/${this.props.match.params.id}/signupFromMenu`}
                                onClick={() => this.signUpNow()}
                              >
                                <span className="btn-inner--icon">
                                  <i className="ni ni-lock-circle-open" />
                                </span>
                                Subscribe Now
                              </Button>
                            )} */}
                          </div>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Container>
          </section>
          <section className="mb-5">
            <div className="btn-wrapper text-center ">
              <div className="container">
                <div className="row  justify-content-center">
                  <div className="col-10 col-lg-4 mt-3">
                    <div className="text-left">
                      <h6>Buy ingredients online</h6>
                    </div>
                    <div className="text-muted text-left mb-3">
                      <small>
                        Select your retailer and purchase right now.
                      </small>
                    </div>
                    <div className="text-center">
                      <Button
                        // className="btn-icon success mb-3 mb-sm-0 "
                        block
                        color="success"
                        onClick={this.addToBasket}
                        id="whisk-basket"
                        className="btn-icon btn-3"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bag-17" />
                        </span>
                        <span className="btn-inner--text">
                          Add meal plan to cart
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="col-4 col-lg-1 text-center mt-3 align-self-center d-flex justify-content-center">
                    <div className="text-muted text-center mb-3">
                      <small>OR</small>
                    </div>
                  </div>
                  <div className="col-10 col-lg-4 mt-3">
                    <div className="text-left">
                      <h6>
                        Create a shopping list
                        <NavLink
                          href="#"
                          onClick={this.viewList}
                          style={{ float: "right", color: "#2dce89" }}
                        >
                          <b>
                            <u>View list</u>
                          </b>
                        </NavLink>
                      </h6>
                    </div>
                    <div className="text-muted text-left mb-3">
                      <small>Print, email, or view list on your mobile.</small>
                    </div>
                    <div className="text-center">
                      <Button
                        // className="btn-icon success mb-3 mb-sm-0 "
                        // id="whisk-basket"
                        onClick={this.addToList}
                        block
                        color="success"
                        outline
                        className="btn-icon btn-3"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-bullet-list-67" />
                        </span>
                        <span className="btn-inner--text">
                          Add to shopping list
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-wrapper text-center mb-5">
              <div className="container">
                <div className="row  justify-content-center">
                  <div className="col-10 col-lg-4 mt-3">
                    <div className="text-center">
                      <small>
                        Powered by{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.whisk.com"
                        >
                          Whisk
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
          <section className="pb-5">
            <ScreenCapture
              hideButton={true}
              title={"Seamlesly Integrate & Save Time"}
            />
          </section>
          <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
          <section>
            <FaqComponent />
          </section>
          <section className="section-grey">
            <ContactUsBanner />
          </section>
        </main>

        <MainFooter />
      </>
    );
  }
}

MenuPage.contextType = UserContext;
export default withRouter(MenuPage);
