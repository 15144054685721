
import React from "react";
import ReactDOM from "react-dom";


import App from './App';
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
// import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/css/argon-design-system-react.css";


ReactDOM.render(<App />, document.getElementById('root'));