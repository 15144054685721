import React from "react";
import { Redirect } from "react-router";
import { logEvent } from "../../firebase/firebase.js";

// core components
import UserContext from "../../contexts/UserContext.js";
import LoginComponent from "../../components/auths/LoginComponent.js";

function Login(props) {
  return (
    <UserContext.Consumer>
      {({ authUser }) => {
        var isLoggedIn = false;
        if (authUser) {
          authUser.isAnonymous ? (isLoggedIn = false) : (isLoggedIn = true);
        }
        if (isLoggedIn && !props.match.params.id) {
          return <Redirect to="/" />;
        } else if (isLoggedIn && props.match.params.id) {
          logEvent("loginAfterNoAuth","none");
          return (
            <Redirect
              to={`/menus/menu/${props.match.params.id}/${props.match.params.action}`}
            />
          );
        } else {
          if (props.match.params.id)
            return (
              <LoginComponent
                id={props.match.params.id}
                action={props.match.params.action}
              />
            );
          else return <LoginComponent />;
        }
      }}
    </UserContext.Consumer>
  );
}

export default Login;
