import React from "react";
import { Redirect } from "react-router";


// core components
import ProfileComponent from "../components/auths/ProfileComponent.js";
import UserContext from "../contexts/UserContext.js"

class Profile extends React.Component {
  render() {
    return (
        <UserContext.Consumer>
        {({ authUser, userName, userFavorites }) => {
          if (!authUser) {
            return <Redirect to="/sign-up" />;
          } else {
            return (
             <ProfileComponent {...{userName, userFavorites}}/>
            );
          }
        }}
      </UserContext.Consumer>
    );
  }
}

export default Profile;
