/* eslint-disable no-unused-expressions */

import React from "react";
import { Link } from "react-router-dom";
import UserContext from "../../contexts/UserContext.js";
import LoginButton from "./LoginButton.js";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class MainNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-xl-5" to="/" tag={Link}>
                <img
                  alt="..."
                  className="logo-navbar"
                  src={require("assets/img/brand/popbodega-logo-master-resized.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="10">
                      <LoginButton />
                    </Col>
                    <Col className="collapse-close" xs="2">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>

                <Nav
                  className="navbar-nav-hover align-items-lg-center navbar-menu"
                  navbar
                >
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      to="/how-it-works"
                      tag={Link}
                      nav
                      className="nav-links"
                    >
                      <span className="nav-link-inner--text">HOW IT WORKS</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      to="/menus"
                      tag={Link}
                      nav
                      className="nav-links"
                    >
                      <span className="nav-link-inner--text">MENUS</span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UserContext.Consumer>
                    {({ paidUser }) => {
                      if (!paidUser) {
                        return (
                          <UncontrolledDropdown nav>
                            <DropdownToggle
                              to="/plans"
                              tag={Link}
                              nav
                              className="nav-links"
                            >
                              <span className="nav-link-inner--text">
                                PLANS
                              </span>
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        );
                      }
                      else{
                        return (
                          <UncontrolledDropdown nav>
                            <DropdownToggle
                              to="/plans"
                              tag={Link}
                              nav
                              className="nav-links"
                            >
                              <span className="nav-link-inner--text">
                                FAQ
                              </span>
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        );
                      }
                    }}
                  </UserContext.Consumer>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <LoginButton />
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          <div className="header--fixed"></div>
        </header>
      </>
    );
  }
}

export default MainNavbar;
