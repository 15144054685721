import MainFooter from "components/Footers/MainFooter";
import MainNavbar from "components/Navbars/MainNavbar";
import React from "react";
import LoadingOverlay from "react-loading-overlay";

import { stripePortalRef } from "../firebase/firebase.js";

class CustomerPortal extends React.Component {
  state = { isActive: true };

  componentDidMount() {
    this.navigateToPortal();
  }

  async navigateToPortal() {
    const { data } = await stripePortalRef({
      returnUrl: window.location.origin,
    });
    window.location.assign(data.url);
    this.setState({isActive:false});
  }

  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
          <section className="section section-shaped signin-container">
          <LoadingOverlay
                active={this.state.isActive}
                spinner
                text="Loading..."
              >
            <div className="customer-portal">
              
            </div>
            </LoadingOverlay>
          </section>
        </main>
        <MainFooter />
      </>
    );
  }
}

export default CustomerPortal;
