import React from "react";

import {
    db,
    firestore,
    firebaseStorage,
    storageCreateRef,
    createJpgFileGivenRef,
  } from "../../firebase/firebase.js";
  
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  UncontrolledAlert,
} from "reactstrap";

class Instructions extends React.Component {
  state = {
    instructions: [],
    pic: null,
    showSuccess: false,
    showError: false,
    showWarning: false,
    recipeId: null,
    sortId: null
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  // On file select (from the pop up)
  onPicChange = (event) => {
    // Update the state
    this.setState({ pic: event.target.files[0] });
  };

  handleSubmit(event) {
    var that = this;
    event.preventDefault();
    const recipeId = this.props.id;
    const sortId = event.target.sortId.value;
    db.collection("recipes").doc(recipeId).collection("instructions").add({
        desc: event.target.desc.value,
        sortId: parseInt(event.target.sortId.value)
    })
    .then(function (docRef) {
        let uploadMainPicTask = createJpgFileGivenRef(
            that.state.pic,
            `inst-${that.props.id.toString()}-${sortId}`,
          storageCreateRef(`recipes/${recipeId}/instructions`)
        )
        uploadMainPicTask.on(
            firebaseStorage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function (snapshot) {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebaseStorage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebaseStorage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  that.setState({showWarning: true});
                  break;
              }
            },
            function (error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
  
                case "storage/canceled":
                  // User canceled the upload
                  break;
                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            function () {
              // Upload completed successfully, now we can get the download URL
              uploadMainPicTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  docRef.set({
                    stepImgUrl: downloadURL
                  }, { merge: true });
                  console.log("File available at", downloadURL);
                  that.setState({ showSuccess: true });
                  that.setState({ showError: false });
                  that.setState({showWarning: false})
                });
            }
          );
    })
  }

  componentDidMount() {
    var that = this;
    let instructions = [...this.state.instructions];
    db.collection("recipes")
      .doc(this.props.id)
      .collection("instructions")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempInstruction = {
            desc: doc.data().desc,
            sortId: doc.data().sortId,
            stepImgUrl: doc.data().stepImgUrl,
            id: doc.id,
          };
          instructions.push(tempInstruction);
        });
        that.setState({ instructions });
      })
      .catch(function (error) {
        console.error("No instruction found ", error);
      });
  }

  render() {
    const id = this.props.id;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Sort Id</th>
            </tr>
          </thead>
          {this.state.instructions.map(function (item) {
            return (
              <tr>
                {/* <th scope="row">{that.counter}</th> */}
                <td>{item.desc}</td>
                <td>{item.sortId}</td>
              </tr>
            );
          })}
        </table>
        <Container>
          <div className="row justify-content-center text-center my-4">
            <div className="col-12">
              <Button
                className="btn-icon btn-3 btn-success"
                type="button"
                onClick={() => this.toggleModal("formModal")}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Add Instruction</span>
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Add Instruction</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit.bind(this)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                    <textarea rows = "5" cols = "60" name = "desc">
            Enter details here...
         </textarea>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Sort ID"
                        type="number"
                        name="sortId"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Picture
                      <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        required
                        onChange={this.onPicChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Add Instruction
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Added Instruction
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="danger"
          fade={true}
          style={{
            display: this.state.showError ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Error!</strong> Couldn't add instruction. Contact system
            admin. email.
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="warning"
          fade={true}
          style={{
            display: this.state.showWarning ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Uploading!</strong> Images being uploaded. please don't
            close the page
          </span>
        </UncontrolledAlert>
      </>
    );
  }
}

export default Instructions;
