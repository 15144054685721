/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class MainFooter extends React.Component {
  render() {
    return (
      <>
        <hr className="footer-border"></hr>
        <footer className=" footer">
          <Container>
            <Row>
              <Nav className=" nav-footer footer-links">
                <Col lg="12" className="footer-column">
                  <NavItem>
                    <NavLink to="/how-it-works" tag={Link}>
                      How It Works
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/menus" tag={Link}>
                      Menus
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/plans" tag={Link}>
                      Plans & Pricing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                  <NavLink to="/contact-us" tag={Link}>
                      Contact Us
                    </NavLink>
                  </NavItem>
                </Col>
              </Nav>
              <Nav className=" nav-footer footer-links">
                <Col lg="12" className="footer-column">
                  <NavItem>
                    <NavLink to="/faq" tag={Link}>
                      FAQ
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/terms-of-service"
                    >
                      Terms of Service
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                       href="/privacy"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                </Col>
              </Nav>
            </Row>
            <Row className="text-center justify-content-center">
              <Col className="btn-wrapper footer-width " lg="6">
                {/* <Button
                  className="btn-icon-only rounded-circle"
                  color="twitter"
                  href="https://twitter.com/popdega"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-twitter" />
                  </span>
                </Button> */}
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/thepopdega"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="dribbble"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/popdega"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-instagram" />
                  </span>
                </Button>
              </Col>
            </Row>
            <Row className="text-center justify-content-center pt-4">
              <Col className="btn-wrapper footer-width " lg="6">
                <div className="text-center">
                  <small>
                    <a href="mailto: info@popdega.com">info@popdega.com</a>
                  </small>
                </div>
              </Col>
            </Row>
            <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
            <Row className=" align-items-center justify-content-center">
              <Col md="6 text-align-center">
                <div className=" copyright">
                  © {new Date().getFullYear()} by Popdega LLC. All Rights
                  Reserved.
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default MainFooter;
