import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  createUserWithEmailAndPassword,
  fbSignInWithPopup,
  signInWithGoogle,
} from "../../firebase/firebase.js";

class SignUpHeadless extends React.Component {
  state = { email: "", name: "", password: "", showInfo: false };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentDidMount() {
  //   // this.handleEmailChange = this.handleEmailChange.bind(this);
  //   // this.handleSubmit = this.handleSubmit.bind(this);
  //   logEvent("SignUpInitiated", "none");
  // }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
    var lowerCaseLetters = /[a-z]/g;
    var numbers = /[0-9]/g;
    if (
      e.target.value.match(lowerCaseLetters) &&
      e.target.value.match(numbers) &&
      e.target.value.length >= 6
    ) {
      this.setState({ showInfo: false });
    } else {
      this.setState({ showInfo: true });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    try {
      createUserWithEmailAndPassword(
        this.state.name,
        this.state.email,
        this.state.password
      );
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    var that = this;
    return (
      <section className="section section-shaped auth-container">
        <Container>
          <Row className="justify-content-center">
            <Container className="bg-white pb-5">
              <Row className="justify-content-center text-center section-heading pb-2">
                <Col lg="8">
                  <h2 className="display-3">Get Started</h2>
                  <p className="lead-slogan text-muted">
                    Reduce food waste. Cook fanciful meals. Save time and
                    money...
                  </p>
                </Col>
              </Row>
              <Row className=" flex-column-reverse flex-lg-row">
                <Col lg="6" className="bg-white">
                  <Row>
                    <Col lg="12">
                      <ul className="list-unstyled">
                        <li className="py-2 pr-2">
                          <div className="d-flex px-3 signup-howItWorks">
                            <div>
                              <img
                                alt="Popdega - Choose A Meal Plan"
                                className="img-center signup-howItWorksImg "
                                src={require("assets/img/theme/howItWorks-1-circle.svg")}
                              />
                            </div>
                            <div className="pl-4">
                              <h5 className="title signup-howItWorks-title">
                                Choose your meals
                              </h5>
                              <p className="text-left">
                                We offer zero food waste meal plans.
                                Alternatively, you can select indivual recipes,
                                mix and match them.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2 pr-2">
                          <div className="d-flex px-3 signup-howItWorks">
                            <div>
                              <img
                                alt="Popdega - Get Your Groceries"
                                className="img-center  signup-howItWorksImg"
                                src={require("assets/img/theme/howItWorks-2-circle.svg")}
                              />
                            </div>
                            <div className="pl-4">
                              <h5 className="title text-left signup-howItWorks-title">
                                Get your groceries
                              </h5>
                              <p className="text-left">
                                We automatically add the ingredients to your
                                grocery store cart or create a digital shopping
                                list.
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2 pr-2">
                          <div className="d-flex px-3 signup-howItWorks">
                            <div>
                              <img
                                alt="Popdega - Cook and Enjoy!"
                                className="img-center signup-howItWorksImg"
                                src={require("assets/img/theme/howItWorks-3-circle.svg")}
                              />
                            </div>
                            <div className="pl-4">
                              <h5 className="title text-left signup-howItWorks-title">
                                Cook and enjoy!
                              </h5>
                              <p className="text-left">
                                Follow our easy instructions online to learn new
                                skills and create amazing meals.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col lg="6" className="bg-white">
                  <div className="text-muted text-center mb-3">
                    <small>Sign up with</small>
                  </div>
                  <div className="text-center">
                    <Button
                      className="btn-neutral btn-icon mr-4 signIn-button"
                      color="default"
                      href="#"
                      onClick={fbSignInWithPopup}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="Facebook logo"
                          src={require("assets/img/icons/common/facebook.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Facebook</span>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon ml-1"
                      color="default"
                      onClick={signInWithGoogle}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="Google logo"
                          src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                  <hr style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
                  <div className="text-center text-muted mb-4">
                    <small>Or sign up using credentials</small>
                  </div>

                  <Row className="justify-content-center">
                    <Col lg="8" md="6">
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: this.state.nameFocused,
                          })}
                        >
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Name"
                              type="text"
                              value={this.state.name}
                              onChange={this.handleNameChange.bind(this)}
                              onFocus={(e) =>
                                this.setState({ nameFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ nameFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: this.state.emailFocused,
                          })}
                        >
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Email"
                              type="email"
                              value={this.state.email}
                              onChange={this.handleEmailChange.bind(this)}
                              onFocus={(e) =>
                                this.setState({ emailFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.passwordFocused,
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              pattern="(?=.*\d)(?=.*[a-z]).{6,}"
                              value={this.state.password}
                              onChange={this.handlePasswordChange.bind(this)}
                              onFocus={(e) =>
                                this.setState({ passwordFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ passwordFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <div
                          className="text-muted"
                          style={{
                            display: this.state.showInfo ? "block" : "none",
                          }}
                        >
                          <small>
                            Password must contain at least one number and at
                            least 6 or more characters
                          </small>
                        </div>
                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="primary"
                            type="submit"
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                      <Row className="pt-3 text-center justify-content-center">
                        <Col lg="12" sm="10" xs="10">
                          <div className="text-muted">
                            <small>
                              By clicking above, you agree to our{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/terms-of-service"
                              >
                                Terms of Service
                              </a>{" "}
                              and consent to our{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="/privacy"
                              >
                                Privacy Policy
                              </a>
                            </small>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3 hr-hideDesktop">
                        <Col className="text-align-center" xs="12">
                          {that.props.id || that.props.action ? (
                            <Link
                              to={`/login/${that.props.id}/${that.props.action}`}
                            >
                              <a>
                                <p>
                                  {" "}
                                  Already have an account? <u>Log In</u>
                                </p>
                              </a>
                            </Link>
                          ) : (
                            <Link to="login">
                              <a>
                                <p>
                                  {" "}
                                  Already have an account? <u>Log In</u>
                                </p>
                              </a>
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr
                    className="hr-hideDesktop"
                    style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                  />
                </Col>
              </Row>
              <Row className="mt-3 hr-hideMobile">
                <Col className="text-align-center" xs="12">
                  {that.props.id || that.props.action ? (
                    <Link to={`/login/${that.props.id}/${that.props.action}`}>
                      <a>
                        <medium>
                          {" "}
                          Already have an account? <u>Log In</u>
                        </medium>
                      </a>
                    </Link>
                  ) : (
                    <Link to="login">
                      <a>
                        <medium>
                          {" "}
                          Already have an account? <u>Log In</u>
                        </medium>
                      </a>
                    </Link>
                  )}
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </section>
    );
  }
}

export default SignUpHeadless;
