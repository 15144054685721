import React from "react";
import Slider from "react-slick";

import { Card, CardBody, Row, Col } from "reactstrap";

const settings = {
  centerMode: true,
  // arrows: true,
  dots: true,
  infinite: false,
  centerPadding: "30px",
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "250px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 769,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "150px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
  dotsClass: "slick-dots",

  customPaging: (i) => <p href="#">{i + 1}</p>,
};

function HowItWorks(props) {
  return (
    <>
      <div className="container">
        {props.hideTitle ? null : (
          <Row className="justify-content-center text-center section-heading-top ">
            <Col lg="12">
              <h2 className="display-3">How It Works</h2>
            </Col>
          </Row>
        )}
        <Slider {...settings} className="border-0 ">
          <Col lg="12" className="h-100 card-padding">
            <Card className=" shadow border-0 rounded-xl  my-5 ">
              <CardBody className="home-page-cards ">
                <img
                  alt="Popdega - Choose A Meal Plan"
                  className="img-center img-fluid img-howItWorks "
                  src={require("assets/img/theme/howItWorks-1.svg")}
                />
                <div className="text-center">
                  <div className="circle"> 1 </div>
                  <h4 className="text-align-center div-inlineBlock pl-2 responsive-text">
                    Choose your meals
                  </h4>
                </div>
                <p className="card-description mt-3 text-center">
                  We offer zero food waste meal plans. Alternatively, you can select indivual
                  recipes, mix and match them.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12" className="h-100 card-padding">
            <Card className="shadow border-0 rounded-xl  my-5 ">
              <CardBody className="home-page-cards ">
                <img
                  alt="Popdega - Get Your Groceries"
                  className="img-center img-fluid img-howItWorks"
                  src={require("assets/img/theme/howItWorks-2.svg")}
                />
                <div className="text-center">
                  <div className="circle"> 2 </div>
                  <h4 className="text-align-center div-inlineBlock pl-2 responsive-text">
                    Get your groceries
                  </h4>
                </div>
                <p className="card-description mt-3 text-center">
                  Automatically add the ingredients to your grocery store
                  cart or create a digital shopping list to shop in-store.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="12" className="h-100 card-padding">
            <Card className="shadow border-0 rounded-xl  my-5 ">
              <CardBody className="home-page-cards">
                <img
                  alt="Popdega - Cook and Enjoy!"
                  className="img-center img-fluid img-howItWorks"
                  src={require("assets/img/theme/howItWorks-3.svg")}
                />
                <div className="text-center">
                  <div className="circle"> 3 </div>
                  <h4 className="text-align-center div-inlineBlock pl-2 responsive-text">
                    Cook and enjoy!
                  </h4>
                </div>
                <p className="card-description mt-3 text-center">
                  Follow our easy instructions online to learn new skills and
                  create amazing meals.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Slider>
      </div>
    </>
  );
}

export default HowItWorks;
