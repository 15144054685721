import React from "react";

import { db, firestore } from "../../firebase/firebase.js";

import {
  Button,
  Container,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
} from "reactstrap";

class AddRecipesToMenu extends React.Component {
  state = { menuRecipes: [], allRecipes: [] };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleChange(e) {
    this.setState({ selectValue: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let recipeRef = db.doc(`/recipes/${this.state.selectValue}`);

    db.collection("menus")
      .doc(this.props.id)
      .set(
        {
          recipes: firestore.FieldValue.arrayUnion(recipeRef),
        },
        { merge: true }
      )
  }

  componentDidMount() {
    const recipeId = this.props.id;
    var that = this;
    let menuRecipes = [...this.state.menuRecipes];
    let allRecipes = [...this.state.allRecipes];
    db.collection("menus")
      .doc(recipeId)
      .get()
      .then((doc) => {
        if (doc.data().recipes != null) {
          doc.data().recipes.forEach(function (ref) {
            ref.get().then((doc) => {
              let tempRecipe = {
                id: doc.id,
                name: doc.data().displayName,
              };
              menuRecipes.push(tempRecipe);
              that.setState({ menuRecipes });
            });
          });
        }
      });
    db.collection("recipes")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempRecipe = {
            path: doc.ref.path,
            id: doc.id,
            name: doc.data().displayName,
          };
          allRecipes.push(tempRecipe);
          that.setState({ allRecipes });
        });
      });
  }

  render() {
    var that = this;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
            </tr>
          </thead>
          {this.state.menuRecipes.map((item) => {
            return (
              <tr>
                <td>{item.name}</td>
              </tr>
            );
          })}
        </table>
        <Container>
          <div className="row justify-content-center text-center my-4">
            <div className="col-12">
              <Button
                className="btn-icon btn-3 btn-success"
                type="button"
                onClick={() => this.toggleModal("formModal")}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Add Recipe</span>
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Create Recipe</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit.bind(this)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <label for="exampleFormControlSelect1">
                        Select a recipe
                      </label>
                      <Input
                        required
                        type="select"
                        className="form-control"
                        id="selectRecipe"
                        onChange={this.handleChange.bind(this)}
                      >
                        {that.state.allRecipes.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Add Recipe
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddRecipesToMenu;
