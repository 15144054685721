import React from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Button } from "reactstrap";

function ContactUsBanner() {
  return (
    <section className="pb-4">
      <Container className="py-lg-md d-flex justify-content-center">
        <Row className="justify-content-center text-center">
          <Col lg="8">
            <div className="section-heading-top pb-3">
              <h4 className="display-3 text-center">Have Questions?</h4>
              <p className="text-center pt-3">
                If you are having a problem ordering or have any questions about
                our services, you can reach out to us anytime and we'll get back
                to you as soon as possible so you can start enjoying your
                delicous meals.
              </p>
            </div>
            <Row className="justify-content-center text-center">
              <Col lg="6">
                <Link to="/contact-us">
                  <Button
                    color="theme"
                    className="btn btn-lg"
                  >
                    <span className="btn-inner--text">Contact Us</span>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactUsBanner;
