/* eslint-disable no-unused-expressions */

import React from "react";
import { Link } from "react-router-dom";
import UserContext from "../../contexts/UserContext.js";
import { signOut, stripePortalRef } from "../../firebase/firebase.js";
import LoadingOverlay from "react-loading-overlay"

import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Nav,
} from "reactstrap";

const ModeratorAccess = (props) => {
  console.log("is admin", props.isAdmin);
  if (props.isAdmin) {
    return (
      <DropdownItem to="/moderator-portal" tag={Link}>
        Moderator Portal
      </DropdownItem>
    );
  } else {
    return <></>;
  }
};

class LoginButton extends React.Component {
  state = { isActive: false };

  async navigateToPortal() {
    this.setState({ isLoading: true });
    const { data } = await stripePortalRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ isAdmin, authUser, paidUser }) => {
          if (!authUser || authUser.isAnonymous) {
            return (
              <>
                <Link to="/login">
                  <Button to="/login" className="btn-invisible">
                    <span className="nav-link-inner--text mr-1 ">Log In</span>
                  </Button>
                </Link>
                <Link to="/sign-up">
                  <Button to="/sign-up" color="warning">
                    <span className="nav-link-inner--text mr-1 ">Sign Up</span>
                  </Button>
                </Link>
              </>
            );
          } else {
            return (
              <LoadingOverlay
                active={this.state.isActive}
                spinner
                text="Loading..."
              >
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">
                        {authUser.displayName}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ModeratorAccess isAdmin={isAdmin} />
                      <DropdownItem to="/profile" tag={Link}>
                        My Meal Plans
                      </DropdownItem>
                      {paidUser ? (
                        <DropdownItem to="/profile/customer-portal" tag={Link}>
                          Manage Subscription
                        </DropdownItem>
                      ) : null}
                      <DropdownItem onClick={signOut}>Logout</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </LoadingOverlay>
            );
          }
        }}
      </UserContext.Consumer>
    );
  }
}
export default LoginButton;
