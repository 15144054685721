import React from "react";
import HowItWorks from "components/HomePage/HowItWorks.js";
import MainNavbar from "components/Navbars/MainNavbar";
import MainFooter from "components/Footers/MainFooter";
import FaqComponent from "components/FaqComponent";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";

class HowItWorksPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section howItWorks-banner">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex hero-text-container">
                <div className="col px-0">
                  <Row className="justify-content-center text-center">
                    <Col lg="12" sm="6" xs="6">
                      <h1 className="display-3 text-white hero-text-slogan">
                        This is how we do it.
                      </h1>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
          <HowItWorks hideTitle={true}></HowItWorks>
          <Container>
            <Row className="justify-content-center text-center px-5 pb-5">
              <Col lg="6">
                <Link to="/menus">
                  <Button color="success" className="btn btn-lg ">
                    <span className="btn-inner--text">GET COOKING</span>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
          <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
          <FaqComponent />
        </main>
        <MainFooter />
      </>
    );
  }
}

export default HowItWorksPage;
