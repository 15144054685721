import React from "react";

import MainFooter from "components/Footers/MainFooter.js";
import GetStarted from "../GetStarted.js";
import { Redirect } from "react-router";
import UserContext from "../../contexts/UserContext.js";

import { logEvent } from "../../firebase/firebase.js";

class SignUp extends React.Component {
  state = {
    signedUp: false,
    plans: false,
    isLoggedIn: false,
  };

  toggleNavs = (state, index) => {
    this.setState({
      [state]: index,
    });
  };

  componentDidMount() {
    if (this.context.authUser) {
      this.context.authUser.isAnonymous
        ? this.setState({ isLoggedIn: false })
        : this.setState({ isLoggedIn: true });
    }
    if (this.state.isLoggedIn) {
      this.setState({ navPills: 2 });
    }
  }

  render() {
    var that = this;
    return (
      <>
        <UserContext.Consumer>
          {({ authUser, paidUser }) => {
            var tabIndex = 1;
            if (!authUser || authUser.isAnonymous) {
              tabIndex = 1;
            } else if (
              paidUser &&
              (!that.props.match.params.id ||
                that.props.match.params.action === "plan")
            ) {
              logEvent("newSubscription", "none");
              return <Redirect to="/" />;
            } else if (paidUser && that.props.match.params.id) {
              logEvent("newSubscription", "none");
              return (
                <Redirect
                  to={`/menus/menu/${that.props.match.params.id}/${that.props.match.params.action}`}
                />
              );
            } else {
              tabIndex = 2;
            }
            if (
              !that.props.match.params.id ||
              that.props.match.params.action === "plan"
            ) {
              logEvent("SignUpInitiated", "none");
            }
            return (
              <GetStarted
                tabIndex={tabIndex}
                planId={
                  that.props.match.params.action === "plan"
                    ? that.props.match.params.id
                    : null
                }
              />
            );
          }}
        </UserContext.Consumer>

        <MainFooter />
      </>
    );
  }
}

SignUp.contextType = UserContext;
export default SignUp;
