import React from "react";

import Gallery from "react-photo-gallery";

const photos = [
  {
    src: require("assets/img/theme/cust-1.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 1"
  },
  {
    src: require("assets/img/theme/cust-2.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 2"
  },
  {
    src: require("assets/img/theme/cust-3.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 3"
  },
  {
    src: require("assets/img/theme/cust-4.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 4"
  },
  {
    src: require("assets/img/theme/cust-5.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 5"
  },
  {
    src: require("assets/img/theme/cust-6.jpeg"),
    width: 2,
    height: 1.5,
    alt: "Popdega customer showcase 6"
  },
];

function CustomerPhotos() {
  return (
    <>
      <div className="section-heading">
        <h2 className="display-3 text-center">Share Your Creation #popdega</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <Gallery columns={3} direction={"column"} photos={photos} />
        </div>
      </div>

      <div className="py-3">
        <p className="text-center font-weight-normal roboto-font">
          Follow us on Instagram{" "}
          <a  target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/popdega/"> @popdega</a>
        </p>
      </div>
    </>
  );
}

export default CustomerPhotos;
