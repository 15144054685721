import React from "react";

import Helmet from "react-helmet";

import MainNavbar from "components/Navbars/MainNavbar";
import MainFooter from "components/Footers/MainFooter";
import PlansComponent from "components/PlansComponent.js";

class Plans extends React.Component {

  componentDidMount(){
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{"Plans & Pricing | Popdega"}</title>
          <meta
            name="description"
            content="Reduce food waste, cook fanciful meals, save time and money with Popdega's chef-designed meal plans straight from your grocery store."
          />
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <PlansComponent />
        </main>
        <MainFooter />
      </>
    );
  }
}
export default Plans;
