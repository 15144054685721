import React from "react";

import { db } from "../../firebase/firebase.js";
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  UncontrolledAlert
} from "reactstrap";

class MenuTags extends React.Component {
  state = {
    tags: [],
    defaultModal: false,
    displayName: "",
    sortId: null,
    showSuccess: false,
    showError: false,
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleNameChange(e) {
    this.setState({ displayName: e.target.value });
  }

  handleSortIdChange(e) {
    this.setState({ sortId: e.target.value });
  }

  handleSubmit(event) {
    var that = this;
    event.preventDefault();
    try {
      db.collection("menuTags")
        .add({
          displayName: that.state.displayName,
          isArchived: false,
          sortId: that.state.sortId,
        })
        .then(function (docRef) {
          that.setState({ showSuccess: true });
          that.setState({ showError: false });
          that.setState({defaultModal: false});
        })
        .catch(function (error) {
          that.setState({ showError: true });
          that.setState({ showSuccess: false });
          that.setState({defaultModal: false});
        });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.handleSubmit = this.handleSubmit.bind(this);
    let tags = [...this.state.tags];
    var that = this;
    db.collection("menuTags")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          tags.push({ key: doc.id, value: doc.data().displayName });
        });
        that.setState({ tags });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  render() {
    return (
      <>
       <ul className="list-group">
        {this.state.tags.map(function (item) {
          return (
            <li className="list-group-item">{item.value}</li>
          );
        })}
        </ul>
        <Container>
          <div className="row justify-content-center text-center my-4">
            <div className="col-12">
              <Button
                className="btn-icon btn-3 btn-success"
                type="button"
                onClick={() => this.toggleModal("formModal")}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Create Menu Tag</span>
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Create Menu Tag</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Display Name"
                        type="text"
                        required
                        value={this.state.displayName}
                        onChange={this.handleNameChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Sort ID"
                        type="number"
                        required
                        value={this.state.sortId}
                        onChange={this.handleSortIdChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Create
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Menu Tag Created
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="danger"
          fade={true}
          style={{
            display: this.state.showError ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Error!</strong> Couldn't create menu tag. Contact system
            admin. email.
          </span>
        </UncontrolledAlert>
      </>
    );
  }
}

export default MenuTags;
