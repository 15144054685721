import React from "react";

import { Card, CardBody, Row, Col } from "reactstrap";
import Slider from "react-slick";

import "react-multi-carousel/lib/styles.css";

const settings = {
  // centerMode: true,
  // arrows: true,
  dots: true,
  infinite: true,
  centerPadding: "30px",
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
  dotsClass: "slick-dots slick-comments",

  // customPaging: (i) => <a>{i + 1}</a>,
};

function WhatPeopleSay(props) {
  var background = "comment-cards";
  if (props.background === "white") {
    background = "comment-cards-white";
  }

  var colSize = 12;
  if(props.showImage){
    colSize = 8;
    settings.slidesToShow = 2;
  }
  return (
    <div className="container">
      <Row className="justify-content-center text-center section-heading pt-0">
        <Col lg="8">
          <h2 className="display-3">What People Say</h2>
        </Col>
      </Row>
      <div>
        <Row>
          {props.showImage ? (
            <Col lg="4">
              <img
                className="rounded-circle img-center comment-image"
                src={require("assets/img/theme/reduceFoodWaste.jpg")}
                alt="Reduce Food Waste with Popdega"
              ></img>
            </Col>
          ) : null}
          <Col lg={`${colSize}`}>
            <Slider {...settings} className="border-0 ">
              <div className="col-lg-12">
                <Card
                  className={`
                     ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "So impressed by the quality of the recipes! And the best
                      part is I can shop at my local grocery store."
                    </p>
                    <p className="text-primary text-align-center ">
                      Larry Gillum, Maryland
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12">
                <Card
                  className={`
                  ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "Really delicious 100% plant based options that I NEVER
                      would imagine trying to make on my own."
                    </p>
                    <p className="text-primary text-align-center ">
                      Jeffrey Kimball, Florida
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12">
                <Card
                  className={`
                 ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "We feel like we are eating at a restaurant when we cook
                      their meals because they are THAT good!"
                    </p>
                    <p className="text-primary text-align-center ">
                      Kate Harrison, California
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12">
                <Card
                  className={`
                  ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "I love how I don't have to plan a week worth of meals and
                      worry about what to make with leftover ingredients."
                    </p>
                    <p className="text-primary text-align-center ">
                      Evan Ustlu, Washington DC
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12">
                <Card
                  className={`
                  ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "For me the best part is knowing where my food comes from
                      and being able to select organic, non-GMO alternatives."
                    </p>
                    <p className="text-primary text-align-center ">
                      John Dunn, New Jersey
                    </p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12">
                <Card
                  className={`
                  ${background}  justify-content-center text-center`}
                >
                  <CardBody>
                    <p className="card-description mt-3 comment-text">
                      "Thanks to Popdega, I can enjoy delicious recipes without
                      hunting down ingredients at the store."
                    </p>
                    <p className="text-primary text-align-center ">
                      Tara Lewis, Seattle
                    </p>
                  </CardBody>
                </Card>
              </div>
            </Slider>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default WhatPeopleSay;
