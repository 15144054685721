import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  UncontrolledAlert,
  NavLink,
} from "reactstrap";

import Helmet from "react-helmet";

// core components
import MainNavbar from "../Navbars/MainNavbar.js";
import MainFooter from "../Footers/MainFooter.js";
import {
  signInWithGoogle,
  signInWithEmailAndPassword,
  fbSignInWithPopup,
  auth,
} from "../../firebase/firebase.js";

class LoginComponent extends React.Component {
  state = {
    email: "",
    password: "",
    showInfo: false,
    showSuccess: false,
    showError: false,
    showLoginError: false
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.handlePwdResetSubmit = this.handlePwdResetSubmit.bind(this);
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
    var lowerCaseLetters = /[a-z]/g;
    var numbers = /[0-9]/g;
    if (
      e.target.value.match(lowerCaseLetters) &&
      e.target.value.match(numbers) &&
      e.target.value.length >= 6
    ) {
      this.setState({ showInfo: false });
    } else {
      this.setState({ showInfo: true });
    }
  }

  handleSubmit(event) {
    var that = this;
    event.preventDefault();
    signInWithEmailAndPassword(this.state.email, this.state.password).catch(
      (error) => {
        console.log(error);
        that.setState({showLoginError:true})
      }
    );
  }

  handlePwdResetSubmit(event) {
    event.preventDefault();
    var that = this;
    auth
      .sendPasswordResetEmail(event.target.fgtEmail.value)
      .then(() => {
        that.setState({ showSuccess: true });
        that.setState({ showError: false });
        that.toggleModal("formModal");
      })
      .catch((error) => {
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
      });
  }

  render() {
    var that = this;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login | Popdega</title>
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <section className="section section-shaped signin-container">
            <Container>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <Button
                          className="btn-neutral btn-icon signIn-button"
                          color="default"
                          onClick={fbSignInWithPopup}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="Facebook logo"
                              src={require("assets/img/icons/common/facebook.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Facebook</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          onClick={signInWithGoogle}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="Google logo"
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign in using credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: this.state.emailFocused,
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Email"
                              type="email"
                              value={this.state.email}
                              onChange={this.handleEmailChange.bind(this)}
                              onFocus={(e) =>
                                this.setState({ emailFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: this.state.passwordFocused,
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              pattern="(?=.*\d)(?=.*[a-z]).{6,}"
                              value={this.state.password}
                              onChange={this.handlePasswordChange.bind(this)}
                              onFocus={(e) =>
                                this.setState({ passwordFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ passwordFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <div
                          className="text-muted"
                          style={{
                            display: this.state.showInfo ? "block" : "none",
                          }}
                        >
                          <small>
                            Password must contain at least one number and at
                            least 6 or more characters
                          </small>
                        </div>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                      <Col className="text-align-center">
                        <NavLink
                          href="#"
                          onClick={() => this.toggleModal("formModal")}
                        >
                          <u>
                            <small>Forgot password?</small>
                          </u>
                        </NavLink>
                      </Col>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col className="text-align-center" xs="12">
                      {that.props.id || that.props.action ? (
                        <Link
                          to={`/sign-up/${that.props.id}/${that.props.action}`}
                        >
                          <a className="text-white">
                            {" "}
                            Don't have an account? <u>Sign Up</u>
                          </a>
                        </Link>
                      ) : (
                        <Link to="/sign-up">
                          <a className="text-white">
                            {" "}
                            Don't have an account? <u>Sign Up</u>
                          </a>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <UncontrolledAlert
            color="danger"
            fade={true}
            style={{
              display: this.state.showLoginError ? "block" : "none",
            }}
          >
            <span className="alert-inner--text">
              <strong>Login Failed!</strong> Please check your email and password then try again. 
            </span>
          </UncontrolledAlert>
          <UncontrolledAlert
            color="danger"
            fade={true}
            style={{
              display: this.state.showError ? "block" : "none",
            }}
          >
            <span className="alert-inner--text">
              <strong>Error!</strong> Something went wrong. Please check if you
              entered correct email address.
            </span>
          </UncontrolledAlert>
        </main>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Password reset email has been sent.
          </span>
        </UncontrolledAlert>
        <MainFooter />
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-4">
                  <medium>Reset your password</medium>
                </div>
                <Form role="form" onSubmit={this.handlePwdResetSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: this.state.forgotEmailFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Email"
                        type="email"
                        name="fgtEmail"
                        onFocus={(e) =>
                          this.setState({ forgotEmailFocused: true })
                        }
                        onBlur={(e) =>
                          this.setState({ forgotEmailFocused: false })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-4" color="primary" type="submit">
                      Reset Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default LoginComponent;
