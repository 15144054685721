import React from "react";
// nodejs library that concatenates classes

import { Row } from "reactstrap";

import Helmet from "react-helmet";
// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import MainFooter from "components/Footers/MainFooter";

// homepage components
import HowItWorks from "components/HomePage/HowItWorks.js";
import WhyPopdega from "components/HomePage/WhyPopdega.js";
import WhatPeopleSay from "components/HomePage/WhatPeopleSay.js";
import CustomerPhotos from "components/HomePage/CustomerPhotos.js";
import SubscribeNewsletter from "components/HomePage/SubscribeNewsletter.js";
import HeroBanner from "components/HomePage/HeroBanner";
import Integrations from "components/HomePage/Integrations.js";
import ScreenCapture from "components/HomePage/ScreenCapture.js";
import MealPlansBanner from "components/HomePage/MealPlansBanner.js";

class Home extends React.Component {
  state = { email: "", showInfo: false };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{"Chef-Designed Meal Plans | Popdega"}</title>
          <meta
            name="description"
            content="Reduce food waste, cook fanciful meals, save time and money with Popdega's chef-designed meal plans straight from your grocery store."
          />
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <HeroBanner></HeroBanner>
          <Row id="howItWorks">
            <HowItWorks></HowItWorks>
          </Row>
          <div
            className="flexbox-container section-grey homepage-section"
            id="freeCTA"
          >
            <MealPlansBanner></MealPlansBanner>
          </div>
          {/* <div className="flexbox-container section-grey pb-5" id="freeCTA">
            <FreeCTA></FreeCTA>
          </div> */}
          <div className="flexbox-container homepage-section" id="integrations">
            <Integrations></Integrations>
          </div>
          <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
          <ScreenCapture className="homepage-section"></ScreenCapture>

          <div className="flexbox-container mt-5">
            <Row id="whyPopdega">
              <WhyPopdega></WhyPopdega>
            </Row>
          </div>
          <div
            className="flexbox-container section-grey pb-5"
            id="whatPoeplSay"
          >
            <div style={{ width: "100%" }}>
              <WhatPeopleSay background="grey"></WhatPeopleSay>
            </div>
          </div>
          <div className="flexbox-container" id="customerPhotos">
            <div style={{ width: "100%" }}>
              <CustomerPhotos></CustomerPhotos>
            </div>
          </div>
          <div className="flexbox-container section-grey mt-5">
            <div style={{ width: "100%" }} id="subscribeNewsletter">
              <SubscribeNewsletter></SubscribeNewsletter>
            </div>
          </div>
        </main>
        <MainFooter />
      </>
    );
  }
}

export default Home;
