import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Button } from "reactstrap";


import { logEvent } from "../firebase/firebase.js";

import Helmet from "react-helmet";

import MainNavbar from "components/Navbars/MainNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
class NotFoundPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    logEvent("pageNotFound", window.location.href);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>404 | Popdega</title>
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <div className="container  mb-5">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="four_zero_four_bg notFound-text">
                  <h1 className="text-center notFound-text-h1">404</h1>
                </div>
                <div className="contant_box_404 text-center">
                  <h3 className="h2">Looks like you're lost</h3>
                  <p>the page you are looking for is not avaible!</p>
                  <div className="btn-wrapper">
                    <Link to="/">
                      <Button color="success" size="lg">
                        Go to home
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <MainFooter />
      </>
    );
  }
}
export default withRouter(NotFoundPage);
