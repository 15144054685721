import React from "react";
import Slider from "react-slick";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { Card, CardBody, Button } from "reactstrap";

import { db } from "../firebase/firebase.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  centerMode: true,
  // infinite: false,
  centerPadding: "60px",
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 1,
      },
    },
  ],
};

class MealPlan extends React.Component {
  state = { mealRecipes: [] };

  componentDidMount() {
    var that = this;
    let mealRecipes = [...this.state.mealRecipes];
    if(!this.props.isEmpty){
      db.collection("menus")
      .doc(that.props.mealId)
      .get()
      .then((doc) => {
        that.setState({ mealName: doc.data().displayName });
        doc.data().recipes.forEach(function (ref) {
          ref.get().then((doc) => {
            let tempRecipe = {
              img: doc.data().thumbPicUrl,
              name: doc.data().displayName,
            };
            mealRecipes.push(tempRecipe);
            that.setState({ mealRecipes });
          });
        });
      });
    }
    
  }

  render() {
    return (
        <div>
        {/* card-lift--hover-sm  */}

      {this.props.isEmpty ? <div hidden></div> : 
        <div className="shadow-md border-0 menu-boxes mb-4 background-grey">
          <div>
            <div className="container pt-3">
              <div className="row justify-content-center">
                <div className="col-8">
                  <h4>{this.state.mealName}</h4>
                </div>
              </div>
            </div>
          </div>
          <Slider {...settings}>
            {this.state.mealRecipes.map((item) => {
              return (
                <Card className="border-0 background-grey">
                  <CardBody className="menu-page-cardBody">
                    <img
                      alt={item.name}
                      className="img-center card-img img-fluid img-menus "
                      src={item.img}
                    />
                    <h6 className="text-align-center pt-3">{item.name}</h6>
                  </CardBody>
                </Card>
              );
            })}
          </Slider>
          <div className="container pb-3">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="text-center btn-wrapper">
                  <Link
                    to={`/menus/${this.state.mealName}/${this.props.mealId}`}
                  >
                    <Button color="success">
                      <span className="btn-inner--text">VIEW MEAL PLAN</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
  }
      </div>
     
    );
  }
}

export default withRouter(MealPlan);
