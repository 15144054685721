import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_DATABSAE_URL,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID,
};

// Replace with your cloud functions location
const functionLocation = 'us-east4';

// This is our firebaseui configuration object
const uiConfig = {
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      clientId:
        "787900633991-d8867oqidi8tu5e8u2ua95c0571h50ds.apps.googleusercontent.com",
    },
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  tosUrl: "/terms-of-service", // This doesn't exist yet
};

const userFavorites = [];

export const myFirebase = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const storage = firebase.storage();
export const firebaseStorage = firebase.storage;
firebase.analytics();

// This sets up firebaseui
const ui = new firebaseui.auth.AuthUI(firebase.auth());

// This adds firebaseui to the page
// It does everything else on its own
export const startFirebaseUI = function (elementId) {
  ui.start(elementId, uiConfig);
};

export const resetFirebaseUI = () => {
  ui.reset();
};

export const logEvent = (type, eventName) =>
  firebase.analytics().logEvent(type, { name: eventName });

export const enablePersistance = () =>
  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

export const addToNewsletter = (email) => {
  var ref = db.collection("newsletter").doc(email);
  ref.get().then((doc) => {
    if (!doc.exists) {
      ref.set({
        email: email,
        isActive: true,
      });
    }
  });
};

export const stripePortalRef = firebase
  .app()
  .functions(functionLocation)
  .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

export const auth = firebase.auth();
/** To sign up using google
 * Link for developer documentation (https://firebase.google.com/docs/auth/web/google-signin)
 */
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  promt: "select_account",
});

export const getCustomClaimRole = async function getCustomClaimRole() {
  await firebase.auth().currentUser.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
};

export const reloadAuthUser = () => {
  if (firebase.auth().currentUser) {
    firebase
      .auth()
      .currentUser.reload()
      .then(() => {});
  } else {
    console.log("No authenticated user");
  }
};

export const sendPasswordResetEmail = (emailAddress) =>
  auth
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      return;
    })
    .catch(function (error) {
      console.log(error);
      return;
    });

export const signOut = () =>
  auth
    .signOut()
    .then(() => {
      signInAnon();
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });

export const signInAnon = () =>
  auth
    .signInAnonymously()
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });

export const signInWithEmailAndPassword = (email, password) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => {})
    .catch((error) => {
      // console.log(error);
      throw "Invalid email or password.";
      var errorCode = error.code;
      var errorMessage = error.message;
    });

export const newSubscriberEmail = (email) => {
  db.collection("mail")
    .add({
      to: "info@popdega.com",
      message: {
        subject: "New Subscriber - Newsletter Homepage",
        text: "email: " + email,
      },
    })
    .then(() => console.log("Queued email for delivery!"));
};
export const newAccountEmail = (email, name) => {
  db.collection("mail").add({
    to: "info@popdega.com",
    message: {
      subject: "New Account Created",
      text: "Name: " + name + "\n" + "Email: " + email + "\n" + "From: Email",
    },
  });
};

export const signInWithGoogle = () =>
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      // The signed-in user info.
      var user = result.user;
      // ...
      var userRef = db.collection("users").doc(user.uid);

      userRef.get().then((doc) => {
        if (!doc.exists) {
          userRef.set({
            email: user.email,
            createdDate: firestore.FieldValue.serverTimestamp(),
            displayName: user.displayName,
            isActive: true,
            isSubscribedToMailing: true,
            userId: user.uid,
            type: "Google",
            favoriteMenus: userFavorites,
          });
          addToNewsletter(user.email);
          newAccountEmail(user.email, user.displayName);
        }
      });
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });

export const createUserWithEmailAndPassword = (name, email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userData) => {
      userData.user
        .updateProfile({
          displayName: name,
        })
        .then(
          function () {
            db.collection("users")
              .doc(userData.user.uid)
              .set({
                email: userData.user.email,
                createdDate: firestore.FieldValue.serverTimestamp(),
                displayName: userData.user.displayName,
                isActive: true,
                isSubscribedToMailing: true,
                userId: userData.user.uid,
                type: "Email",
                favoriteMenus: userFavorites,
              })
              .then(function (docRef) {
                auth
                  .signOut()
                  .then(auth.signInWithEmailAndPassword(email, password));
                addToNewsletter(userData.user.email);
                newAccountEmail(userData.user.email, userData.user.displayName);
              });
          },
          function (error) {
            console.log(error);
            // An error happened.
          }
        );
      // Signed in
      // ...
    })
    .catch((error) => {
      console.log(error);
      var errorCode = error.code;
      var errorMessage = error.message;
    });

var fbProvider = new firebase.auth.FacebookAuthProvider();
fbProvider.setCustomParameters({
  display: "popup",
});

export const fbSignInWithPopup = () =>
  auth
    .signInWithPopup(fbProvider)
    .then(function (result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // ...
      var userRef = db.collection("users").doc(user.uid);

      userRef.get().then((doc) => {
        if (!doc.exists) {
          userRef.set({
            email: user.email,
            createdDate: firestore.FieldValue.serverTimestamp(),
            displayName: user.displayName,
            isActive: true,
            isSubscribedToMailing: true,
            userId: user.uid,
            type: "Facebook",
            favoriteMenus: userFavorites,
          });
          addToNewsletter(user.email);
          newAccountEmail(user.email, user.displayName);
        }
      });
    })
    .catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });

export const storageCreateRef = (location) => {
  var storageRef = storage.ref();
  return storageRef.child(location);
};

export const createJpgFileGivenRef = (file, fileName, ref) => {
  // Create the file metadata
  var metadata = {
    contentType: "image/jpg",
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  var uploadTask = ref.child(fileName).put(file, metadata);
  return uploadTask;
};

export const submitContactUsForm = (email, name, message) => {
  db.collection("contactUsForm").add({
    email: email,
    name: name,
    message: message,
  });
};
