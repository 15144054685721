import React from "react";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import { HashLink } from "react-router-hash-link";
function FaqComponent() {
  const data = {
    title: "Frequently Asked Questions",
    rows: [
      {
        title: "How often do you release new meal plans and recipes?",
        content: (
          <p>
            We publish new meal plans and recipes every week. Subscribe to our
            newsletter to get notified when we put up new meal plans.{" "}
            <HashLink to="/#subscribeNewsletter" className="nav-links">
              <u>Subscribe now!</u>
            </HashLink>
          </p>
        ),
      },
      {
        title: "How does Popdega reduce my food waste?",
        content: (
          <p>
            All of our meal plans are zero-waste, which means after you complete
            the plan there will be no food waste. So that when you
            purchase a meal plan and you don't need to worry about any leftover
            ingredients. Saving money and the planet.
          </p>
        ),
      },
      {
        title: "Do indivual recipes create food waste?",
        content: (
          <p>
            Yes, we give you the option to purchase single recipes and they
            might yield leftover ingredients. At this time only our meal plans
            are zero food waste. However, we are always open to recommendations,
            if you would like to see no-waste recipes then{" "}
            <Link to="/contact-us">
              <u>let us know here.</u>
            </Link>
          </p>
        ),
      },
      {
        title: "Which grocery stores can I order from?",
        content: (
          <p>
            We integrate with plenty of grocery stores throughout the United
            States such as, Amazon Fresh, Instacart, Walmart, Kroger, Shoprite,
            Vons, Albertsons, Safeway, Giant, Harris Teeter, Ralphs, and many
            more...
          </p>
        ),
      },
      {
        title: "How much does Popdega cost?",
        content: (
          <p>
            We offer 7-days free trial, after that we have variety of
            subscription plans: $9.95/month, $24.99/3-month, and $44.99/6-month
            options.
          </p>
        ),
      },
      {
        title: "How can I cancel my subscription?",
        content: (
          <p>
            You can easily cancel your subscription any time using our customer
            portal. No need call or email us. It's that simple!
          </p>
        ),
      },
      // {
      //   title: "How many grocery stores do you integrate with?",
      //   content: (
      //     <p>
      //       We integrate with hundreds of grocery store chains in United States
      //       and Europe including Amazon Fresh, Instacart, Walmart, Kroger, Tesco
      //       and many more. If your retailer is not available you can always
      //       create a digital shopping list to take it to the store.
      //     </p>
      //   ),
      // },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  return (
    <Container>
      <Row className="justify-content-center py-6 px-3">
        <Col lg="8">
          <Faq data={data} styles={styles} config={config} />
        </Col>
      </Row>
    </Container>
  );
}

export default FaqComponent;
