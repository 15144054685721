import React from "react";
import { Link } from "react-router-dom";
import {
  db,
  firestore,
  firebaseStorage,
  storageCreateRef,
  createJpgFileGivenRef,
} from "../../firebase/firebase.js";

import { withRouter } from "react-router";

import {
  Button,
  Container,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Modal,
  UncontrolledAlert,
} from "reactstrap";

class Recipes extends React.Component {
  state = {
    recipes: [],
    thumbFile: null,
    mainPicFile: null,
    showSuccess: false,
    showError: false,
    showWarning: false,
    ingredientPic: false,
    menuTags: [],
    checkedItems: new Map(),
  };

  toggleWithId = (state, id) => {
    this.setState({
      [state]: !this.state[state],
    });
    this.setState({ id: id });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  deleteCheckboxState = (name, checked) => {
    const updateChecked = typeof checked === "undefined" ? true : false;
    this.setState((prevState) =>
      prevState.checkedItems.set(name, updateChecked)
    );
  };

  handleAddLeftOver(event){
    console.log(event.target.desc.value);
    console.log(event.target.ref.value);
    event.preventDefault();
    db.collection("recipes")
    .doc(this.state.id)
    .set(
      {
        usesLeftOver: true,
        leftOverDesc: event.target.desc.value,
        leftOver: db.doc(`/recipes/${event.target.ref.value}`)
      },
      { merge: true }
    );

  }

  handleTagSubmit(event) {
    event.preventDefault();

    let selectedTags = [];
    this.state.checkedItems.forEach((key, value) => {
      selectedTags.push(db.doc(`/menuTags/${value}`));
    });

    db.collection("recipes")
      .doc(this.state.id)
      .set(
        {
          tags: firestore.FieldValue.arrayUnion.apply(this, selectedTags),
        },
        { merge: true }
      );
  }

  handleSubmit(event) {
    var that = this;
    event.preventDefault();

    db.collection("recipes")
      .add({
        displayName: event.target.name.value,
        serving: parseInt(event.target.serving.value),
        calories: parseInt(event.target.calories.value),
        duration: parseInt(event.target.duration.value),
        createdDate: firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        let uploadMainPicTask = createJpgFileGivenRef(
          that.state.mainPicFile,
          `mainPic-recipe-${docRef.id}`,
          storageCreateRef(`recipes/${docRef.id}`)
        );
        uploadMainPicTask.on(
          firebaseStorage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebaseStorage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebaseStorage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                that.setState({ showWarning: true });
                break;
            }
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function () {
            // Upload completed successfully, now we can get the download URL
            uploadMainPicTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                docRef.set(
                  {
                    mainPicUrl: downloadURL,
                  },
                  { merge: true }
                );
                console.log("File available at", downloadURL);
                that.setState({ showSuccess: true });
                that.setState({ showError: false });
                that.setState({ showWarning: false });
              });
          }
        );
        let uploadThumbPicTask = createJpgFileGivenRef(
          that.state.thumbFile,
          `thumbPic-recipe-${docRef.id}`,
          storageCreateRef(`recipes/${docRef.id}`)
        );
        uploadThumbPicTask.on(
          firebaseStorage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebaseStorage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebaseStorage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                that.setState({ showWarning: true });
                break;
            }
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function () {
            // Upload completed successfully, now we can get the download URL
            uploadThumbPicTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                docRef.set(
                  {
                    thumbPicUrl: downloadURL,
                  },
                  { merge: true }
                );
                console.log("File available at", downloadURL);
                that.setState({ showSuccess: true });
                that.setState({ showError: false });
                that.setState({ showWarning: false });
              });
          }
        );
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
        that.setState({ showWarning: false });
      });
  }

  // On file select (from the pop up)
  onThumbChange = (event) => {
    // Update the state
    this.setState({ thumbFile: event.target.files[0] });
  };

  onMainPicChange = (event) => {
    // Update the state
    this.setState({ mainPicFile: event.target.files[0] });
  };

  onIngredientPicChange = (event) => {
    this.setState({ ingredientPic: event.target.files[0] });
  };

  handleAddIngredient(event) {
    var that = this;
    event.preventDefault();
    let isPantry = false;
    let name = event.target.name.value;

    console.log(event.target.isPantry," IS PANTRY");
    if (event.target.isPantry.checked) {
      isPantry = true;
    }

    console.log("ID", this.state.id);
    console.log("name", event.target.name.value);
    console.log("uom", event.target.uom.value);
    console.log("amount", event.target.amount.value);
    console.log("isPantry", isPantry);
    console.log("photo", this.state.ingredientPic);

    db.collection("recipes")
      .doc(this.state.id)
      .collection("ingredients")
      .add({
        displayName: name,
        uom: event.target.uom.value,
        amount: Number(event.target.amount.value),
        isPantry: isPantry,
        imgUrl: event.target.imgUrl.value,
        type: event.target.type.value,
        createdDate: firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        that.setState({ showError: false });
        that.setState({ showSuccess: true });
        that.setState({ showWarning: false });
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
        that.setState({ showWarning: false });
      });
  }

  componentDidMount() {
    var that = this;
    let recipes = [...this.state.recipes];
    db.collection("recipes")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempRecipe = {
            name: doc.data().displayName,
            serving: doc.data().serving,
            duration: doc.data().duration,
            cDate: doc.data().createdDate,
            id: doc.id,
          };
          recipes.push(tempRecipe);
        });
        that.setState({ recipes });
      });

    let menuTags = [...this.state.menuTags];
    db.collection("menuTags")
      .where("isArchived", "!=", "false")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempMenuTag = {
            id: doc.id,
            path: doc.id,
            name: doc.data().displayName,
            ref: doc,
            checked: false,
          };
          menuTags.push(tempMenuTag);
          that.setState({ menuTags });
        });
      });
  }

  render() {
    var that = this;
    const { history } = this.props;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col"></th>
              <th scope="col">Serving</th>
              <th scope="col">Duration</th>
              <th scope="col">Created Date</th>
            </tr>
          </thead>
          <tbody>
            {this.state.recipes.map(function (item) {
              return (
                <tr>
                  {/* <th scope="row">{that.counter}</th> */}
                  
                  <td><Link to={`/recipes/${item.name}/${item.id}`}>{item.name}</Link></td>
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() =>
                        history.push(
                          `/moderator-portal/instructions/${item.id}`
                        )
                      }
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add Instruction</span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() =>
                        that.toggleWithId("ingredientModal", item.id)
                      }
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add Ingredients</span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() => that.toggleWithId("tagModal", item.id)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add Tags</span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() => that.toggleWithId("leftOverModal", item.id)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add LeftOver</span>
                    </Button>
                  </td>
                  {/* <td>{item.serving.toString()}</td>
                  <td>{item.duration.toString()}</td>
                  <td>{item.cDate.toString()}</td> */}
                  {/* <td>
                    {item.tags.map(function (tag) {
                      return tag + " || ";
                    })}
                  </td> */}
                  {/* <td>
                    {item.recipes.map(function (recipe) {
                      return recipe + " || ";
                    })}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Container>
          <div className="row justify-content-center text-center my-4">
            <div className="col-12">
              <Button
                className="btn-icon btn-3 btn-success"
                type="button"
                onClick={() => this.toggleModal("formModal")}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Create Recipe</span>
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Create Recipe</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit.bind(this)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Display Name"
                        type="text"
                        required
                        name="name"
                        value={this.state.displayName}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Serving"
                        type="number"
                        name="serving"
                        required
                        value={this.state.serving}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Calories"
                        type="number"
                        name="calories"
                        required
                        value={this.state.calories}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Duration in Minutes"
                        type="number"
                        name="duration"
                        required
                        value={this.state.duration}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Thumbnail Picture
                      <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        required
                        onChange={this.onThumbChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Recipe Page Banner Picture
                      <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        required
                        onChange={this.onMainPicChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Create Recipe
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.ingredientModal}
          toggle={() => this.toggleModal("ingredientModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Add Ingredient</small>
                </div>
                <Form
                  role="form"
                  onSubmit={this.handleAddIngredient.bind(this)}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Display Name"
                        type="text"
                        required
                        name="name"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Amount"
                        step="any"
                        type="number"
                        name="amount"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Unit of Measure"
                        type="text"
                        name="uom"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input placeholder="Type" type="text" name="type" />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Is Pantry?
                      <Input
                        placeholder="Is Pantry"
                        type="checkbox"
                        name="isPantry"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Img URL"
                        type="text"
                        required
                        name="imgUrl"
                      />
                    </InputGroup>
                  </FormGroup>

                  {/* <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Ingredient Picture
                      <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        required
                        onChange={this.onIngredientPicChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup> */}
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Add Ingredient
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.tagModal}
          toggle={() => this.toggleModal("tagModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Add Tags</small>
                </div>
                <Form role="form" onSubmit={this.handleTagSubmit.bind(this)}>
                  <FormGroup className="mb-3">
                    <div className="text-muted text-center mb-3">
                      <small>Menu Tags</small>
                      {this.state.menuTags.map(function (tag) {
                        return (
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id={tag.id}
                              type="checkbox"
                              checked={!!that.state.checkedItems.get(tag.id)}
                              onChange={that.handleChange}
                              name={tag.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={tag.id}
                            >
                              {tag.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Create
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.leftOverModal}
          toggle={() => this.toggleModal("leftOverModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Add Ingredient</small>
                </div>
                <Form
                  role="form"
                  onSubmit={this.handleAddLeftOver.bind(this)}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Recipe desc"
                        type="text"
                        required
                        name="desc"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Reference"
                        type="text"
                        name="ref"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Add LeftOvers
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Menu Tag Created
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="danger"
          fade={true}
          style={{
            display: this.state.showError ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Error!</strong> Couldn't create menu tag. Contact system
            admin. email.
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="warning"
          fade={true}
          style={{
            display: this.state.showWarning ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Uploading!</strong> Images being uploaded. please don't
            close the page
          </span>
        </UncontrolledAlert>
      </>
    );
  }
}

export default withRouter(Recipes);
