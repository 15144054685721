import React from "react";

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  UncontrolledAlert,
} from "reactstrap";
import {
  db,
  firestore,
  firebaseStorage,
  storageCreateRef,
  createJpgFileGivenRef,
} from "../../firebase/firebase.js";

import { withRouter } from "react-router";

class Menus extends React.Component {
  state = {
    menus: [],
    showSuccess: false,
    showError: false,
    defaultModal: false,
    menuTags: [],
    checkedItems: new Map(),
    showSuccess: false,
    showError: false,
    ingredientPic: false
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  toggleWithId = (state, id) => {
    this.setState({
      [state]: !this.state[state],
    });
    this.setState({ id: id });
  };


  handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  deleteCheckboxState = (name, checked) => {
    const updateChecked = typeof checked === "undefined" ? true : false;
    this.setState((prevState) =>
      prevState.checkedItems.set(name, updateChecked)
    );
  };

  onIngredientPicChange = (event) => {
    this.setState({ ingredientPic: event.target.files[0] });
  };

  handleSubmit(event) {
    var that = this;
    event.preventDefault();

    let selectedTags = [];
    this.state.checkedItems.forEach((key, value) => {
      selectedTags.push(db.doc(`/menuTags/${value}`));
    });
    db.collection("menus")
      .add({
        displayName: event.target.name.value,
        createdDate: firestore.FieldValue.serverTimestamp(),
        tags: selectedTags,
        isArchived: true,
      })
      .then(function (docRef) {
        that.setState({ showSuccess: true });
        that.setState({ showError: false });
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
      });
  }

  // handleAddIngredient(event) {
  //   var that = this;
  //   event.preventDefault();
  //   let isPantry = false;
  //   let name = event.target.name.value;
  //   if (event.target.isPantry == "on") {
  //     isPantry = true;
  //   }

  //   console.log("ID", this.state.id);
  //   console.log("name", event.target.name.value);
  //   console.log("uom", event.target.uom.value);
  //   console.log("amount", event.target.amount.value);
  //   console.log("isPantry", isPantry);
  //   console.log("photo", this.state.ingredientPic);

  //   db.collection("menus")
  //     .doc(this.state.id)
  //     .collection("ingredients")
  //     .add({
  //       displayName: name,
  //       uom: event.target.uom.value,
  //       amount: Number(event.target.amount.value),
  //       isPantry: isPantry,
  //       createdDate: firestore.FieldValue.serverTimestamp(),
  //     })
  //     .then(function (docRef) {
  //       let uploadIngPic = createJpgFileGivenRef(
  //         that.state.ingredientPic,
  //         `${name}-recipe-${docRef.id}`,
  //         storageCreateRef(`recipes/${docRef.id}/ingredients`)
  //       );
  //       uploadIngPic.on(
  //         firebaseStorage.TaskEvent.STATE_CHANGED, // or 'state_changed'
  //         function (snapshot) {
  //           // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //           var progress =
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //           console.log("Upload is " + progress + "% done");
  //           switch (snapshot.state) {
  //             case firebaseStorage.TaskState.PAUSED: // or 'paused'
  //               console.log("Upload is paused");
  //               break;
  //             case firebaseStorage.TaskState.RUNNING: // or 'running'
  //               console.log("Upload is running");
  //               that.setState({ showWarning: true });
  //               break;
  //           }
  //         },
  //         function (error) {
  //           // A full list of error codes is available at
  //           // https://firebase.google.com/docs/storage/web/handle-errors
  //           switch (error.code) {
  //             case "storage/unauthorized":
  //               // User doesn't have permission to access the object
  //               break;

  //             case "storage/canceled":
  //               // User canceled the upload
  //               break;
  //             case "storage/unknown":
  //               // Unknown error occurred, inspect error.serverResponse
  //               break;
  //           }
  //         },
  //         function () {
  //           // Upload completed successfully, now we can get the download URL
  //           uploadIngPic.snapshot.ref
  //             .getDownloadURL()
  //             .then(function (downloadURL) {
  //               docRef.set(
  //                 {
  //                   imgUrl: downloadURL,
  //                 },
  //                 { merge: true }
  //               );
  //               console.log("File available at", downloadURL);
  //               that.setState({ showSuccess: true });
  //               that.setState({ showError: false });
  //               that.setState({ showWarning: false });
  //             });
  //         }
  //       );
  //     })
  //     .catch(function (error) {
  //       console.error("Error adding document: ", error);
  //       that.setState({ showError: true });
  //       that.setState({ showSuccess: false });
  //       that.setState({ showWarning: false });
  //     });
  // }


  handleAddIngredient(event) {
    var that = this;
    event.preventDefault();
    let isPantry = false;
    let name = event.target.name.value;
    if (event.target.isPantry.checked) {
      isPantry = true;
    }

    console.log("ID", this.state.id);
    console.log("name", event.target.name.value);
    console.log("uom", event.target.uom.value);
    console.log("amount", event.target.amount.value);
    console.log("isPantry", isPantry);
    console.log("photo", this.state.ingredientPic);

    db.collection("menus")
      .doc(this.state.id)
      .collection("ingredients")
      .add({
        displayName: name,
        uom: event.target.uom.value,
        amount: Number(event.target.amount.value),
        isPantry: isPantry,
        imgUrl: event.target.picUrl.value,
        createdDate: firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        that.setState({ showError: false });
        that.setState({ showSuccess: true });
        that.setState({ showWarning: false });
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
        that.setState({ showWarning: false });
      });
  }



  componentDidMount() {
    let menus = [...this.state.menus];
    var that = this;
    db.collection("menus")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempMenu = {
            name: doc.data().displayName,
            isArchived: doc.data().isArchived,
            tags: [],
            recipes: [],
            id: doc.id,
          };
          doc.data().tags.forEach(function (ref) {
            ref.get().then((doc) => {
              tempMenu.tags.push(doc.data().displayName);
            });
          });
          if (doc.data().recipes != null) {
            doc.data().recipes.forEach(function (ref) {
              ref.get().then((doc) => {
                tempMenu.recipes.push(doc.data().displayName);
              });
            });
          }
          menus.push(tempMenu);
          that.setState({ menus });
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    let menuTags = [...this.state.menuTags];
    db.collection("menuTags")
      .where("isArchived", "!=", "false")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let tempMenuTag = {
            id: doc.id,
            path: doc.id,
            name: doc.data().displayName,
            ref: doc,
            checked: false,
          };
          menuTags.push(tempMenuTag);
          that.setState({ menuTags });
        });
      });
  }

  render() {
    var that = this;
    const { history } = this.props;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Is Archived</th>
              <th scope="col">Tags</th>
              <th scope="col">Recipes</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.menus.map(function (item) {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.isArchived.toString()}</td>
                  <td>
                    {item.tags.map(function (tag) {
                      return tag + " || ";
                    })}
                  </td>
                  <td>
                    {item.recipes.map(function (recipe) {
                      return recipe + " || ";
                    })}
                  </td>{" "}
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() =>
                        history.push(`/moderator-portal/menus/${item.id}`)
                      }
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add Recipes</span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="btn-icon"
                      outline
                      type="button"
                      onClick={() =>
                        that.toggleWithId("ingredientModal", item.id)
                      }
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Add Ingredients</span>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Container>
          <div className="row justify-content-center text-center my-4">
            <div className="col-12">
              <Button
                className="btn-icon btn-3 btn-success"
                type="button"
                onClick={() => this.toggleModal("formModal")}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Create Menu</span>
              </Button>
            </div>
          </div>
        </Container>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.formModal}
          toggle={() => this.toggleModal("formModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Create Menu</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit.bind(this)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Display Name"
                        type="text"
                        required
                        name="name"
                        value={this.state.name}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="text-muted text-center mb-3">
                      <small>Menu Tags</small>
                      {this.state.menuTags.map(function (tag) {
                        return (
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id={tag.id}
                              type="checkbox"
                              checked={!!that.state.checkedItems.get(tag.id)}
                              onChange={that.handleChange}
                              name={tag.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={tag.id}
                            >
                              {tag.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Create
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.state.ingredientModal}
          toggle={() => this.toggleModal("ingredientModal")}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Add Ingredient</small>
                </div>
                <Form
                  role="form"
                  onSubmit={this.handleAddIngredient.bind(this)}
                >
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Display Name"
                        type="text"
                        required
                        name="name"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Amount"
                        step="any"
                        type="number"
                        name="amount"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Unit of Measure"
                        type="text"
                        name="uom"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Is Pantry?
                      <Input
                        placeholder="Is Pantry"
                        type="checkbox"
                        name="isPantry"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Pic URL"
                        type="text"
                        required
                        name="picUrl"
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      Ingredient Picture
                      <input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        required
                        onChange={this.onIngredientPicChange.bind(this)}
                      />
                    </InputGroup>
                  </FormGroup> */}
                  <div className="text-center">
                    <Button className="my-4" color="warning" type="submit">
                      Add Ingredient
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Menu has been created
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="danger"
          fade={true}
          style={{
            display: this.state.showError ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Error!</strong> Couldn't create menu. Contact system admin.
          </span>
        </UncontrolledAlert>
      </>
    );
  }
}

export default withRouter(Menus);
