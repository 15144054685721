import React from "react";
import classnames from "classnames";

import MainNavbar from "../components/Navbars/MainNavbar.js";
import MainFooter from "../components/Footers/MainFooter.js";
import UserContext from "../contexts/UserContext.js";
import { db } from "../firebase/firebase.js";
import Helmet from "react-helmet";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

class ContactUs extends React.Component {
  state = { showSuccess: false, showError: false };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  handleSubmit(event) {
    var that = this;
    event.preventDefault();
    var email = event.target.email.value;
    var message = event.target.message.value;
    var name = event.target.name.value;
    var uid;
    var hasAccount;
    if (event.target.loggedIn.value === "false") {
      hasAccount = false;
      uid = "";
    } else {
      hasAccount = true;
      uid = event.target.uid.value;
    }

    db.collection("contactUsForm")
      .add({
        name: name,
        email: email,
        message: message,
        hasAccount: hasAccount,
        uid: uid,
      })
      .then(function (docRef) {
        that.setState({ showSuccess: true });
        that.setState({ showError: false });
      })
      .catch(function (error) {
        that.setState({ showError: true });
        that.setState({ showSuccess: false });
      });

    db.collection("mail")
      .add({
        to: "info@popdega.com",
        message: {
          subject: "Form Submitted - Contact Us",
          text:
            "Name: " +
            name +
            "\n" +
            "Email: " +
            email +
            "\n" +
            "Has Account?: " +
            hasAccount +
            "\n" +
            "uid: " +
            uid +
            "\n" +
            "Message: " +
            message,
        },
      })
      .then(() => console.log("Queued email for delivery!"));
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us | Popdega</title>
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <section className="section section-shaped contactUs-container pb-8">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="6">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h1 className="mb-1 text-align-center">
                        Contact Us
                      </h1>
                      <p className="pt-3">We'd love to hear from you.</p>
                      <UserContext.Consumer>
                        {({ loggedIn, uid }) => {
                          return (
                            <Form
                              role="form"
                              onSubmit={this.handleSubmit.bind(this)}
                            >
                              <FormGroup
                                className={classnames("mt-5", {
                                  focused: this.state.nameFocused,
                                })}
                              >
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-user-run" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    required
                                    placeholder="Your name"
                                    type="text"
                                    name="name"
                                    onFocus={(e) =>
                                      this.setState({ nameFocused: true })
                                    }
                                    onBlur={(e) =>
                                      this.setState({ nameFocused: false })
                                    }
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup
                                className={classnames({
                                  focused: this.state.emailFocused,
                                })}
                              >
                                <InputGroup className="input-group-alternative">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="ni ni-email-83" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    required
                                    placeholder="Email address"
                                    type="email"
                                    name="email"
                                    onFocus={(e) =>
                                      this.setState({ emailFocused: true })
                                    }
                                    onBlur={(e) =>
                                      this.setState({ emailFocused: false })
                                    }
                                  />
                                </InputGroup>
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <Input
                                  required
                                  className="form-control-alternative"
                                  cols="80"
                                  name="message"
                                  placeholder="Type a message..."
                                  rows="4"
                                  type="textarea"
                                />
                              </FormGroup>
                              <input
                                type="hidden"
                                name="uid"
                                value={uid}
                              ></input>
                              <input
                                type="hidden"
                                name="loggedIn"
                                value={loggedIn}
                              ></input>
                              <div>
                                <Button
                                  block
                                  className="btn-round"
                                  color="success"
                                  size="lg"
                                  type="submit"
                                >
                                  Send Message
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </UserContext.Consumer>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <UncontrolledAlert
            color="success"
            fade={true}
            style={{
              display: this.state.showSuccess ? "block" : "none",
            }}
          >
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              <strong>Success!</strong> We received your message and get back to
              you as soon as possible.
            </span>
          </UncontrolledAlert>
          <UncontrolledAlert
            color="danger"
            fade={true}
            style={{
              display: this.state.showError ? "block" : "none",
            }}
          >
            <span className="alert-inner--text">
              <strong>Oops!</strong> Something went wrong. Please send us an
              email instead.
            </span>
          </UncontrolledAlert>
        </main>
        <MainFooter />
      </>
    );
  }
}
export default ContactUs;
