import React from "react";
import { Container } from "reactstrap";
import ReactMarkdown from "react-markdown";

const RecipeInstructions = (props) => {
  return (
    <Container>
      <div className="row section-grey mt-2 mb-2">
        <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-4 text-center align-self-center justify-content-center">
          <img src={props.img} alt="instruction" className="instruction-pic mx-3"></img>
        </div>
        <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-8 align-self-center d-flex justify-content-start">
          <ReactMarkdown itemProp="recipeInstructions">{props.desc}</ReactMarkdown>
        </div>
      </div>
    </Container>
  );
};

export default RecipeInstructions;
