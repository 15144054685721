import React from "react";
import { Container, Row, Col } from "reactstrap";

function Integrations() {
  return (
    <>
      <Container className="justify-content-center">
        <Row className="justify-content-center text-center ">
          <Col lg="8">
            <h2 className="display-3">Order From</h2>
          </Col>
        </Row>

        <div className="row">
          <div className="col-4 col-lg-2 d-flex align-items-center justify-content-center">
            <img
              className="integrations"
              alt="Walmart logo"
              src={require("assets/img/integrations/Walmart_Logos_Lockup_horiz_1C_blu_RGB.png")}
            ></img>
          </div>
          <div className="col-4 col-lg-2 d-flex align-items-center justify-content-center">
            <img
              className="integrations"
              alt="Instacart logo"
              src={require("assets/img/integrations/instacart-1.svg")}
            ></img>
          </div>
          <div className="col-4 col-lg-2  d-flex align-items-center justify-content-center">
            <img
              className="integrations-wholefoods"
              alt="Shoprite logo"
              src={require("assets/img/integrations/shop-rite.svg")}
            ></img>
          </div>
          <div className="col-4 col-lg-2  d-flex align-items-center justify-content-center">
            <img
              className="integrations-kroger"
              alt="Harris Teeter logo"
              src={require("assets/img/integrations/harris-teeter-2.svg")}
            ></img>
          </div>
          <div className="col-4 col-lg-2  d-flex align-items-center justify-content-center">
            <img
              className="integrations-kroger"
              alt="Korger logo"
              src={require("assets/img/integrations/current-kroger-logo.svg")}
            ></img>
          </div>
          <div className="col-4 col-lg-2  d-flex align-items-center justify-content-center">
            <img
              className="integrations"
              alt="Safeway logo"
              src={require("assets/img/integrations/safeway.svg")}
            ></img>
          </div>
        </div>
        <Row className="justify-content-center text-center pt-3">
          <Col lg="8">
            <h2 className="display-3">and many more...</h2>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Integrations;
