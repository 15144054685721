import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import { withRouter } from "react-router";
import UserContext from "../contexts/UserContext.js";

class AddMenuFavorite extends React.Component {
  render() {
    return (
      <UserContext.Consumer>
        {({ authUser, userFavorites, updateFavs }) => {
          var isLoggedIn = false;
          if (authUser) {
            authUser.isAnonymous ? (isLoggedIn = false) : (isLoggedIn = true);
          }
          if (userFavorites && userFavorites.includes(this.props.menuId)) {
            return (
              <Button
                className="btn-icon btn-3 btn "
                color="danger"
                type="button"
                size="sm"
                outline
                onClick={() => updateFavs(this.props.menuId, false)}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-favourite-28" />
                </span>

                <span className="btn-inner--text">Unsave</span>
              </Button>
            );
          } else {
            if (!isLoggedIn) {
              return (
                <Link
                  to={`/login/${this.props.menuId}/save`}
                >
                  <Button
                    className="btn-icon btn-3 btn "
                    color="danger"
                    type="button"
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-favourite-28" />
                    </span>

                    <span className="btn-inner--text">Save</span>
                  </Button>
                </Link>
              );
            } else {
              return (
                <Button
                  className="btn-icon btn-3 btn "
                  color="danger"
                  type="button"
                  size="sm"
                  onClick={() => updateFavs(this.props.menuId, true)}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-favourite-28" />
                  </span>

                  <span className="btn-inner--text">Save</span>
                </Button>
              );
            }
          }
        }}
      </UserContext.Consumer>
    );
  }
}
export default withRouter(AddMenuFavorite);
