import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

import Helmet from "react-helmet";

// core components
import MainNavbar from "../Navbars/MainNavbar.js";
import MainFooter from "../Footers/MainFooter.js";
import MealPlan from "../MealPlan.js";
import { useHistory } from "react-router-dom";

function ProfileComponent(props) {
  const history = useHistory();
  var hasSaved = false;
  if(props.userFavorites){
    if(props.userFavorites.length !== 0 ){
      hasSaved = true;
    }
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile | Popdega</title>
      </Helmet>
      <MainNavbar />
      <main className="profile-page py-6">
        <section className="section">
          {hasSaved ? (
            <Row className="justify-content-center text-center">
              <Col lg="8" className="pb-6">
                <h4>{props.userName}'s Favorites</h4>
              </Col>
              <Col lg="8">
                {props.userFavorites.map((item) => {
                  return <MealPlan mealId={item} />;
                })}
              </Col>
            </Row>
          ) : (
            <Row className="justify-content-center text-center">
              <Col lg="8" className="pb-6">
                <h4>You don't have any saved meal plans.</h4>
              </Col>
              <Col lg="8" className="pb-6">
                <div className="btn-wrapper">
                  <button
                    // className="btn-icon success mb-3 mb-sm-0 "
                    onClick={() => history.push("/menus")}
                    className="btn btn-success btn-lg"
                  >
                    <span className="btn-inner--text">View Our Menu</span>
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </section>
      </main>
      <MainFooter />
    </>
  );
}

export default ProfileComponent;
