/* eslint-disable no-unused-expressions */

import React from "react";
import { Link } from "react-router-dom";
import SignUpHeadless from "../components/auths/SingUpHeadless.js";
import PlansComponent from "../components/PlansComponent.js";

import Helmet from "react-helmet";

import classnames from "classnames";

// reactstrap components
import {
  NavLink,
  NavItem,
  Nav,
  Col,
  Row,
  NavbarBrand,
  Container,
} from "reactstrap";

class GetStarted extends React.Component {
  state = {
    navPills: 1,
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    this.props.isLoggedIn ? this.setState({ navPills: 2 }) : null;
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Get Started | Popdega</title>
          <meta
            name="description"
            content="Get started now and sign up to Popdega to reduce food waste, cook fanciful meals, save time and money. Chef-designed meal plans straight from your grocery store."
          />
        </Helmet>
        <header className="header-global pt-2">
          <Container>
            <Row className="justify-content-center">
              <Col lg="4">
                <NavbarBrand className="mr-xl-5" to="/" tag={Link}>
                  <img
                    alt="..."
                    className="logo-navbar"
                    src={require("assets/img/brand/popbodega-logo-master.png")}
                  />
                </NavbarBrand>
              </Col>
              <Col lg="8">
                <Nav
                  className="nav-fill flex-column flex-sm-row"
                  id="tabs-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.navPills === 1}
                      className={classnames("mb-sm-3 mb-md-0 disabled-link", {
                        active: this.props.tabIndex === 1,
                      })}
                      onClick={(e) => this.toggleNavs(e, "navPills", 1)}
                      href="#pablo"
                      role="tab"
                      disabled
                    >
                      1. WELCOME
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.navPills === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.props.tabIndex === 2,
                      })}
                      to="/plans"
                      tag={Link}
                    >
                      2. PLANS & PRICING
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={this.state.navPills === 3}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.props.tabIndex === 3,
                      })}
                      onClick={(e) => this.toggleNavs(e, "navPills", 3)}
                      href="#pablo"
                      role="tab"
                      disabled
                    >
                      3. CHECKOUT
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>

          {/* <Container>
            
          </Container> */}
        </header>
        <main ref="main" className="main-checkout">
          {this.props.tabIndex === 1 ? <SignUpHeadless /> : null}
          {this.props.tabIndex === 2 ? (
            <PlansComponent
              planId={this.props.planId ? this.props.planId : null}
              hideWhySection={true}
            />
          ) : null}
        </main>
      </>
    );
  }
}

export default GetStarted;
