import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
// import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/css/argon-design-system-react.css";
import "react-multi-carousel/lib/styles.css";

import ContactUs from "views/ContactUs.js";
import Home from "views/Home.js";
import Login from "views/auth/Login.js";
import Profile from "views/Profile.js";
import SignUp from "views/auth/SignUp.js";
import UserContext from "contexts/UserContext.js";
import FaqPage from "views/FaqPage.js";
import Menus from "views/Menus.js";
import MenuPage from "views/MenuPage.js";
import Recipe from "views/Recipe.js";
import ModeratorPortal from "views/ModeratorPortal.js";
import NotFoundPage from "views/NotFoundPage.js";
import TermsOfService from "views/Legal/TermsOfService.js";
import Privacy from "views/Legal/Privacy.js";
import HowItWorksPage from "views/HowItWorksPage.js";
import Plans from "views/Plans.js";
import CustomerPortal from "views/CustomerPortal.js";

import {
  auth,
  db,
  firestore,
  enablePersistance,
  logEvent,
  signInAnon,
  getCustomClaimRole,
} from "./firebase/firebase.js";

class App extends React.Component {
  state = {
    loggedIn: false,
    uid: "",
    isAdmin: false,
    userFavorites: [],
    paidUser: false,
  };
  storageIdAuth = "userId";
  storageIdFavs = "favsId";
  storageIdRole = "userRole";

  constructor(props) {
    super(props);
    let authUser = null;
    let userFavorites = null;
    let paidUser = false;

    enablePersistance();

    // setting auth from localstorage
    for (let key in localStorage) {
      if (key === this.storageIdAuth) {
        authUser = localStorage.getItem(this.storageIdAuth);
        break;
      }
    }
    for (let key in localStorage) {
      if (key === this.storageIdFavs) {
        userFavorites = localStorage.getItem(this.storageIdFavs);
        break;
      }
    }
    for (let key in localStorage) {
      if (key === this.storageIdRole) {
        paidUser = localStorage.getItem(this.storageIdRole);
        break;
      }
    }

    this.updateFavs = (menuId, isAdd) => {
      var that = this;
      var userRef = db.collection("users").doc(this.state.authUser.uid);
      if (isAdd) {
        userRef
          .update({
            favoriteMenus: firestore.FieldValue.arrayUnion(menuId),
          })
          .then(function () {
            db.collection("users")
              .doc(that.state.authUser.uid)
              .get()
              .then((doc) => {
                localStorage.setItem(
                  that.storageIdFavs,
                  doc.data().favoriteMenus
                );
                that.setState({ userFavorites: doc.data().favoriteMenus });
                logEvent("addedToFavs", that.storageIdFavs);
              });
          });
      } else {
        userRef
          .update({
            favoriteMenus: firestore.FieldValue.arrayRemove(menuId),
          })
          .then(function () {
            db.collection("users")
              .doc(that.state.authUser.uid)
              .get()
              .then((doc) => {
                localStorage.setItem(
                  that.storageIdFavs,
                  doc.data().favoriteMenus
                );
                that.setState({ userFavorites: doc.data().favoriteMenus });
              });
          });
      }
    };

    this.state = { userFavorites };
    this.state = { authUser };
    this.state = { paidUser };
    this.state = { updateFavs: this.updateFavs };
    if (!authUser) {
      signInAnon();
    }
  }

  async dataListener() {
    let role = await getCustomClaimRole();
    if (role) {
      this.setState({ paidUser: true });
      localStorage.setItem(this.storageIdRole, true);
    } else {
      localStorage.setItem(this.storageIdRole, false);
      this.setState({ paidUser: false });
    }
  }

  componentDidMount() {
    var that = this;

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        if (!authUser.isAnonymous) {
          //check if it's admin
          db.collection("admins")
            .where("adminListArray", "array-contains", authUser.uid)
            .get()
            .then(function (querySnapshot) {
              if (!querySnapshot.empty) {
                that.setState({
                  userName: authUser.displayName,
                  uid: authUser.uid,
                  loggedIn: true,
                  isAdmin: true,
                });
                that.setState({ paidUser: true });
                localStorage.setItem(that.storageIdRole, true);
                localStorage.setItem(that.storageIdAuth, authUser.uid);
              }
            })
            .catch((error) => {
              //check if it's internal user
              console.log(error);
              db.collection("internal")
                .where("bypassPayment", "array-contains", authUser.uid)
                .get()
                .then(function (querySnapshot) {
                  if (!querySnapshot.empty) {
                    that.setState({ paidUser: true });
                    localStorage.setItem(that.storageIdRole, true);
                    that.setState({
                      userName: authUser.displayName,
                      uid: authUser.uid,
                      loggedIn: true,
                      isAdmin: false,
                    });
                    localStorage.setItem(that.storageIdAuth, authUser.uid);
                  } 
                  // it's neither admin nor internal user. so it has to be regular user
                  else {
                    that.dataListener();
                    that.setState({
                      userName: authUser.displayName,
                      uid: authUser.uid,
                      loggedIn: true,
                      isAdmin: false,
                    });
                    localStorage.setItem(that.storageIdAuth, authUser.uid);
                  }
                }).catch((error)=>{console.log("users error",error)})
            });
          db.collection("users")
            .doc(authUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                localStorage.setItem(
                  that.storageIdFavs,
                  doc.data().favoriteMenus
                );
                this.setState({ userFavorites: doc.data().favoriteMenus });
              }
            });
        }
      } else {
        this.setState({
          userName: "",
          uid: "",
          loggedIn: false,
          isAdmin: false,
          paidUser: false
        });
        localStorage.removeItem(that.storageIdAuth);
        localStorage.removeItem(that.storageIdFavs);
        localStorage.removeItem(that.storageIdRole);
        this.setState({ userFavorites: null });
      }
      // change state depending on listener
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }
  render() {
    const {
      loggedIn,
      userName,
      uid,
      isAdmin,
      authUser,
      userFavorites,
      updateFavs,
      paidUser,
    } = this.state;
    return (
      <BrowserRouter>
        <UserContext.Provider
          value={{
            authUser,
            loggedIn,
            userName,
            uid,
            isAdmin,
            userFavorites,
            updateFavs,
            paidUser,
          }}
        >
          <Switch>
            <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route
              path="/login/:id?/:action?"
              exact
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/sign-up/:id?/:action?"
              exact
              render={(props) => <SignUp {...props} />}
            />
            <Route
              path="/terms-of-service"
              exact
              render={(props) => <TermsOfService {...props} />}
            />
            <Route
              path="/privacy"
              exact
              render={(props) => <Privacy {...props} />}
            />
            <Route
              path="/moderator-portal"
              exact
              render={(props) => <ModeratorPortal {...props} />}
            />
            <Route
              path="/how-it-works"
              exact
              render={(props) => <HowItWorksPage {...props} />}
            />
            <Route
              path="/plans"
              exact
              render={(props) => <Plans {...props} />}
            />
            <Route
              path="/moderator-portal/instructions/:recipeId"
              component={ModeratorPortal}
            />
            <Route
              path="/moderator-portal/menus/:menuId"
              component={ModeratorPortal}
            />

            <Route
              path="/profile"
              exact
              render={(props) => <Profile {...props} />}
            />
            <Route
              path="/contact-us"
              exact
              render={(props) => <ContactUs {...props} />}
            />
            <Route
              path="/faq"
              exact
              render={(props) => <FaqPage {...props} />}
            />
            <Route
              path="/profile/customer-portal"
              exact
              render={(props) => <CustomerPortal {...props} />}
            />
            <Route
              path="/menus/:tag?"
              exact
              render={(props) => <Menus {...props}></Menus>}
            />
            <Route
              exact
              path="/menus/:mealName/:id/:action?"
              component={MenuPage}
            />
            <Route exact path="/recipes/:name/:id" component={Recipe} />
            <Route path="*" component={NotFoundPage} />
            <Route onEnter={() => window.location.reload()} />
          </Switch>
        </UserContext.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
