import React from "react";

import { withRouter } from "react-router";
import PlansBanner from "../components/PlansBanner";
import WhatPeopleSay from "../components/HomePage/WhatPeopleSay";
import ScreenCapture from "../components/HomePage/ScreenCapture";
import WhyPopdega from "../components/HomePage/WhyPopdega";
import FaqComponent from "../components/FaqComponent";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "../assets/css/pricingListItem.css";
import { Container, Row, Button, Spinner } from "reactstrap";

import { db, logEvent } from "../firebase/firebase.js";
import UserContext from "contexts/UserContext.js";
import LoadingOverlay from "react-loading-overlay";

// Replace with your tax ids
// https://dashboard.stripe.com/tax-rates
// const taxRates = ["txr_1HCshzHYgolSBA35WkPjzOOi"];

function RenderButton(props) {
  if (props.isLoading && props.selectedPricing === props.priceId) {
    return (
      <Button
        color="success"
        className="btn btn-lg "
        onClick={() =>
          props.this.checkOutHandler(props.priceId, window.location.href)
        }
        disabled
      >
        <span className="btn-inner--text">SELECT PLAN</span>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="pr-2"
        />
        <span className="sr-only">Loading...</span>
      </Button>
    );
  } else if (props.isLoading) {
    return (
      <Button
        color="success"
        className="btn btn-lg "
        disabled
        onClick={() =>
          props.this.checkOutHandler(props.priceId, window.location.href)
        }
      >
        <span className="btn-inner--text">SELECT PLAN</span>
      </Button>
    );
  } else {
    return (
      <Button
        color="success"
        className="btn btn-lg "
        onClick={() =>
          props.this.checkOutHandler(props.priceId, window.location.href)
        }
      >
        <span className="btn-inner--text">SELECT PLAN</span>
      </Button>
    );
  }
}

class PlansComponent extends React.Component {
  state = {
    pricings: [],
    isLoading: false,
    selectedPricing: "",
    isActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let pricings = [...this.state.pricings];
    var that = this;
    if (this.props.planId) {
      this.setState({ isActive: true });
      console.log(this.props.planId, "Plan already selected");
      this.checkOutHandler(
        this.props.planId,
        window.location.origin + "/plans"
      );
    } else {
      db.collection("plans")
        .where("active", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            const priceSnap = await doc.ref
              .collection("prices")
              .orderBy("interval_count", "asc")
              .get();
            priceSnap.docs.forEach((doc) => {
              if (doc.data().active) {
                let tempPricing = {
                  price: (doc.data().unit_amount / 100).toFixed(2),
                  interval: doc.data().interval,
                  interval_count: doc.data().interval_count,
                  priceId: doc.id,
                  currency: doc.data().currency,
                  monthyCost: (
                    doc.data().unit_amount /
                    100 /
                    doc.data().interval_count
                  ).toFixed(2),
                  trial_period_days: doc.data().trial_period_days,
                };
                pricings.push(tempPricing);
              }
            });
            that.setState({ pricings });
          });
        });
    }
  }

  checkOutHandler(priceId, returnUrl) {
    var isLoggedIn = false;
    if (this.context.authUser) {
      this.context.authUser.isAnonymous
        ? (isLoggedIn = false)
        : (isLoggedIn = true);
    }
    if (!isLoggedIn) {
      const { history } = this.props;
      history.push(`/sign-up/${priceId}/plan`);
      return;
    }
    this.setState({ isLoading: true, selectedPricing: priceId });
    // event.preventDefault();
    const docRef = db
      .collection("customers")
      .doc(this.context.authUser.uid)
      .collection("checkout_sessions")
      .add({
        price: priceId,
        allow_promotion_codes: true,
        // tax_rates: taxRates,
        success_url: returnUrl,
        cancel_url: returnUrl,
        metadata: {
          tax_rate: "10% sales tax exclusive",
        },
      })
      .then(function (docRef) {
        docRef.onSnapshot(async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
            await stripe
              .redirectToCheckout({ sessionId: sessionId })
              .then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                console.log(result);
              });
            logEvent("planSelected", "none");
          }
        });
      });
  }

  render() {
    var isLoggedIn = false;
    if (this.context.authUser) {
      this.context.authUser.isAnonymous
        ? (isLoggedIn = false)
        : (isLoggedIn = true);
    }
    return (
      <>
        <PlansBanner />
        <LoadingOverlay active={this.state.isActive} spinner text="Loading...">
          {this.state.isActive ? (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          ) : null}
        </LoadingOverlay>
        <div className="pricingList pricing  my-5">
          <Container>
            <Row>
              {this.state.pricings.map((pricing) => (
                <div className="col-lg-4">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">
                        {pricing.interval_count > 1
                          ? pricing.interval_count + " Months"
                          : pricing.interval_count + " Month"}
                        {pricing.interval_count > 1
                          ? pricing.interval_count === 3
                            ? " - 15% Off"
                            : " - 25% Off"
                          : null}
                      </h5>
                      <h6 className="card-price text-center">
                        ${pricing.monthyCost}
                        <span className="period">/mo</span>
                      </h6>
                      <hr />
                      <ul className="fa-ul">
                        <li>
                          <span className="fa-li">
                            <i className="ni ni-check-bold"></i>
                          </span>
                          {pricing.trial_period_days}-day Trial
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="ni ni-check-bold"></i>
                          </span>
                          Order directly from grocery stores
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="ni ni-check-bold"></i>
                          </span>
                          Create a digital shopping list
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="ni ni-check-bold"></i>
                          </span>
                          Add additional grocery items
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="ni ni-check-bold"></i>
                          </span>
                          Pick-up or get your groceries delivered
                        </li>
                      </ul>
                      <hr />
                      <div className="btn-wrapper text-center">
                        <RenderButton
                          this={this}
                          priceId={pricing.priceId}
                          selectedPricing={this.state.selectedPricing}
                          isLoading={this.state.isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
            {/* <Row className="justify-content-center mt-5">
              <div className="col-lg-6">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      Free
                    </h5>
                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <i className="ni ni-fat-remove"></i>
                        </span>
                        Order directly from grocery stores
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="ni ni-fat-remove"></i>
                        </span>
                        Create a digital shopping list
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="ni ni-fat-remove"></i>
                        </span>
                        Add additional grocery items
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="ni ni-fat-remove"></i>
                        </span>
                        Pick-up or get your groceries delivered
                      </li>
                    </ul>
                    <hr />
                    <div className="btn-wrapper text-center">
                      {isLoggedIn ? (
                        <Link to="/menus">
                          <Button color="success" className="btn btn-lg ">
                            <span className="btn-inner--text">SELECT PLAN</span>
                          </Button>
                        </Link>
                      ) : (
                        <Link to="/sign-up">
                          <Button color="success" className="btn btn-lg ">
                            <span className="btn-inner--text">SELECT PLAN</span>
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Row> */}
          </Container>
        </div>
        <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
        <WhatPeopleSay background="white" showImage={true} />
        {this.props.hideWhySection ? (
          <>
            <hr style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
            <ScreenCapture
              className="mb-5"
              hideButton={true}
              title={"Seamlesly Integrate & Save Time"}
            />
          </>
        ) : (
          <>
            <div className="flexbox-container mt-5">
              <Row id="whyPopdega">
                <WhyPopdega />
              </Row>
            </div>

            <FaqComponent />
          </>
        )}
      </>
    );
  }
}
PlansComponent.contextType = UserContext;
export default withRouter(PlansComponent);
