import React from "react";

import classnames from "classnames";

import MainFooter from "components/Footers/MainFooter";
import MainNavbar from "components/Navbars/MainNavbar";
import MenuTags from "components/Moderator/MenuTags";
import Menus from "components/Moderator/Menus.js";
import Recipes from "components/Moderator/Recipes.js";
import Instructions from "components/Moderator/Instructions.js";
import AddRecipesToMenu from "components/Moderator/AddRecipesToMenu.js";

import { Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";

class ModeratorPortal extends React.Component {
  state = {
    navPills: 1,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  render() {
    var that = this;
    const renderForm = () => {
      if (this.props.match.params.recipeId != null) {
        return <Instructions id={that.props.match.params.recipeId} />;
      } else if (this.props.match.params.menuId != null) {
        return <AddRecipesToMenu id={that.props.match.params.menuId} />;
      } else {
        return (
          <>
            <section id="moderator-nav" className="my-4">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 1,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    Menu Tags
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 2,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    Menus
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabs === 3,
                    })}
                    onClick={(e) => this.toggleNavs(e, "tabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                    Recipes
                  </NavLink>
                </NavItem>
              </Nav>
            </section>
            <section id="moderator">
              <TabContent activeTab={"tabs" + this.state.tabs}>
                <TabPane tabId="tabs1">
                  <MenuTags></MenuTags>
                </TabPane>
                <TabPane tabId="tabs2">
                  <Menus></Menus>
                </TabPane>
                <TabPane tabId="tabs3">
                  <Recipes></Recipes>
                </TabPane>
              </TabContent>
            </section>
          </>
        );
      }
    };

    return (
      <>
        <MainNavbar />
        <main ref="main">{renderForm()}</main>
        <MainFooter />
      </>
    );
  }
}

export default ModeratorPortal;
