import React from "react";

const Ingredients = (props) => {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-6">
      <div className="media pb-3">
        <img
          src={props.picUrl}
          className="align-self-start mr-3 rounded-circle ingredient-pic img-center"
          alt={props.displayName}
        />
        <div className="media-body">
          <h6 itemProp="recipeIngredient" className="mt-0">{props.displayName}</h6>
          {/* <p className="blurry-text "> */}
          <p>
            {props.uom === "none" ? null : props.amount+" "+props.uom} {props.type && props.uom !== "none" ? ", " + props.type : null}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Ingredients;
