import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

function WhyPopdega() {
  return (
    <>
      <section className="banner-container">
        <Container className="banner-inside">
          <Row className="justify-content-center text-center section-heading pb-2">
            <Col lg="8">
              <h2 className="display-3">Why Popdega?</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <div className="col-lg-6">
              <ul className="list-unstyled">
                <li className="py-2 pr-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        alt="Popdega - Environmental Friendly"
                        className="rounded-circle img-center banner-icons"
                        src={require("assets/img/theme/why-1.svg")}
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-left">Environmental Friendly</h5>
                      <p className="text-left">
                        Reduce your food waste. No shipping, no individual
                        packages and no recipe cards.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2 pr-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        alt="Popdega - Get it Your Way"
                        className="rounded-circle img-center banner-icons"
                        src={require("assets/img/theme/why-2.svg")}
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-left">Get it Your Way</h5>
                      <p className="text-left">
                        Create a shopping list to take with you or order online
                        with few clicks.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2 pr-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        alt="Popdega - Learn New Skills"
                        className="rounded-circle img-center banner-icons"
                        src={require("assets/img/theme/why-4.svg")}
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-left">Learn New Skills</h5>
                      <p className="text-left">
                        Cook delicious recipes, try new ingredients and broaden
                        your culinary horizons.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Row>
          <Row className="justify-content-center text-center px-5 pb-5">
            <Col lg="6">
              <Link to="/menus">
                <Button color="success" className="btn btn-lg ">
                  <span className="btn-inner--text">GET COOKING</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WhyPopdega;
