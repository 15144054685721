import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import { Link } from "react-router-dom";
function HeroBanner() {
  return (
    <div className="position-relative">
      <section className="section hero-container">
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="py-lg-md d-flex hero-text-container">
          <div className="col px-0">
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <h1 className="display-3 text-white hero-text-slogan">
                  Chef-Designed Meal Plans
                  <span className="hero-text-sub-slogan">
                    <u>straight from your grocery store</u>
                  </span>
                </h1>
                <p className="lead text-white pt-5 ">
                  <i className="hero-text-least-slogan">
                    Reduce food waste. Cook fanciful meals. Save time and
                    money...
                  </i>
                </p>
                <div className="btn-wrapper">
                  <Link to="/menus">
                    <Button
                      // href="/menus"
                      color="theme"
                      className="btn btn-lg"
                    >
                      <span className="btn-inner--text">GET STARTED</span>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default HeroBanner;
