import React from "react";

import {  Row, Col } from "reactstrap";

function PlansBanner() {
  return (
    <div>
      <Row className="justify-content-center text-center section-heading-top pt-0">
        <Col lg="8">
          <h2 className="display-3">Choose Your Plan</h2>
          <h5 className="text-center pt-3">
            {" "}
            Start your free trial now! Satisfaction guaranteed 💯
          </h5>
        </Col>
      </Row>
    </div>
  );
}

export default PlansBanner;
