import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  CardLink,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

function MealPlansBanner() {
  const vegan = "HdHVoneXN92CjFmNPVlF";
  const vegetarian = "M5objxvrOptqRh5NKsL3";
  const balancedDiet = "HGwsjrkQrfHHambvrhAE";
  return (
    <>
      <Container>
        <Row className="justify-content-center text-center section-heading-top ">
          <Col lg="12">
            <h2 className="display-3">Variety of Plans For Everyone</h2>
          </Col>
        </Row>
      </Container>
      <Container className="py-lg-md d-flex justify-content-center  my-5">
        <Row className="justify-content-center text-center">
          <Col lg="4" className="mb-3">
            <Card className="shadow border-0">
              {/* <a href={`/menus/${vegan}`}> */}
              <a href="/menus">
                <CardImg
                  variant="top"
                  alt="Explore Popdega's Vegan Plans"
                  src={require("assets/img/theme/veganMeal.jpg")}
                />
              </a>
              <CardBody className="pb-5">
                <CardTitle className="plan-banner-title">VEGAN</CardTitle>
                <CardText>
                  Whether you're thinking of going vegan or are already eating
                  that way, you've come to the right place. Try our plant based,
                  non-diary weekly meal plans with zero-waste.
                </CardText>
                {/* <CardLink href={`/menus/${vegan}`}> */}
                <CardLink href="/menus"> 
                  Explore Vegan Plans
                </CardLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" className="mb-3">
            <Card className="shadow border-0">
              {/* <a href={`/menus/${balancedDiet}`}> */}
              <a href="/menus">
                <CardImg
                  variant="top"
                  alt="Explore Popdega's Balanced Plans"
                  src={require("assets/img/theme/balancedMeal.jpg")}
                />
              </a>
              <CardBody className="pb-5">
                <CardTitle className="plan-banner-title">
                  BALANCED DIET
                </CardTitle>
                <CardText>
                  Designed to allow you to begin consistently eating nutritious,
                  balanced meals with plenty of choices including seafood and
                  meat while avoiding food waste.
                </CardText>
                {/* <CardLink href={`/menus/${balancedDiet}`}> */}
                <CardLink href="/menus">
                  Explore Balanced Diet Plans
                </CardLink>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" className="mb-3">
            <Card className="shadow border-0">
              {/* <a href={`/menus/${vegetarian}`}> */}
              <a href="/menus">
                <CardImg
                  variant="top"
                  alt="Explore Popdega's Vegetarian Plans"
                  src={require("assets/img/theme/vegetarianMeal.jpg")}
                />
              </a>
              <CardBody className="pb-5">
                <CardTitle className="plan-banner-title">VEGETARIAN</CardTitle>
                <CardText>
                  If you're a vegetarian, or want to cut back on meat, make sure
                  you're getting all the nutrients you need and cut down your
                  food waste with our plant-based meal plans.
                </CardText>
                {/* <CardLink href={`/menus/${vegetarian}`}> */}
                <CardLink href="/menus">
                  Explore Vegetarian Plans
                </CardLink>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="6">
            <Link to="/menus">
              <Button color="success" className="btn btn-lg ">
                <span className="btn-inner--text">VIEW MEAL PLANS</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MealPlansBanner;
