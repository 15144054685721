import React from "react";
import { Row, Container, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

function ScreenCapture(props) {
  return (
    <section className="mt-3">
      {/* {props.title ? (
        <Row className="justify-content-center text-center section-heading-top  mb-5">
          <Col lg="12">
            <h2 className="display-3">{props.title}</h2>
          </Col>
        </Row>
      ) : null} */}

      <Row className="justify-content-center text-center section-heading-top  mb-5">
        <Col lg="12">
          <h2 className="display-3">Seamlessly Integrate & Save Time</h2>
        </Col>
      </Row>

      <Container>
        <div className="row">
          <div className="col-12">
            <img
              alt="Popdega - Add to cart or create a list"
              className="img-fluid img-center"
              src={require("assets/img/theme/device-capture.png")}
            />
          </div>
        </div>
        {props.hideButton ? null : (
          <Row className="justify-content-center text-center pt-5">
            <Col lg="6">
              <Link to="/menus">
                <Button color="theme" className="btn btn-lg ">
                  <span className="btn-inner--text">TRY NOW</span>
                </Button>
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
}

export default ScreenCapture;
