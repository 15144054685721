import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Container, Row, Button } from "reactstrap";

class LeftOvers extends React.Component {
  state = { recipeData: null, recipeId: null };

  componentDidMount() {
    var that = this;
    this.props.lefOver.get().then((doc) => {
      that.setState({ recipeData: doc.data(), recipeId: doc.id });
    });
  }

  redirectToRecipe = (history, name, id) => {
    history.push(`/recipes/${name}/${id}`);
    history.go(0);
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-center text-center pt-3">
          <div className="col-12">
            <h4>Use Leftovers From</h4>
          </div>
        </Row>
        <Row className="pb-3 pt-3">
          {this.state.recipeData ? (
            <div className="col-12 d-flex flex-row justify-content-center">
              <div className="media ">
                <img
                  alt={this.state.recipeData.displayName}
                  className="align-self-center mr-3 leftOver-pic img-center"
                  style={{
                    width: "140px",
                    height: "140px",
                    borderRadius: "10% !important",
                  }}
                  src={this.state.recipeData.thumbPicUrl}
                />
                <div className="media-body">
                  <h6 itemProp="recipeIngredient" className="mt-0">
                    {this.state.recipeData.displayName}
                  </h6>
                  {this.props.leftOverDesc != "none" ? (
                    <p>{this.props.leftOverDesc}</p>
                  ) : <p></p>}

                  <Link
                    to={`/recipes/${this.state.recipeData.displayName}/${this.state.recipeId}`}
                  >
                    <Button
                      className="btn banner-button"
                      size="sm"
                      onClick={() =>
                        this.redirectToRecipe(
                          this.props.history,
                          this.state.recipeData.displayName,
                          this.state.recipeId
                        )
                      }
                    >
                      <span className="btn-inner--text">View Recipe</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </Row>
      </Container>
    );
  }
}

export default withRouter(LeftOvers);
