import React from "react";
import { withRouter } from "react-router";
import { db, logEvent } from "../firebase/firebase.js";
import { Container, Row, Col, Button, NavLink } from "reactstrap";

import Helmet from "react-helmet";

import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import MainNavbar from "components/Navbars/MainNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import Instructions from "components/Instructions.js";
import Ingredients from "components/Ingredients.js";
import LeftOvers from "components/LefOvers.js";
import UserContext from "contexts/UserContext.js";

const VIEW_LIST = "viewList";
const ADD_LIST = "addList";
const ADD_CART = "addCart";
const SAVE = "save";

class Recipe extends React.Component {
  state = {
    img: null,
    duration: null,
    serving: null,
    calories: null,
    recipeData: [],
    instructions: [],
    ingredients: [],
    shoppingList: [],
    recipeName: "",
    loading: true,
  };

  componentDidMount() {
    // const id = this.props.match.params.id;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    var that = this;

    this.addToList = this.addToList.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.viewList = this.viewList.bind(this);

    this.setState({ id: this.props.match.params.id });
    this.setState({ id: this.props.match.params.name });
    let instructions = [...this.state.instructions];
    let ingredients = [...this.state.ingredients];
    var shoppingList = [...this.state.shoppingList];
    db.collection("recipes")
      .doc(this.props.match.params.id)
      .get()
      .then((doc) => {
        that.setState({ recipeData: doc.data() });
        that.setState({ recipeName: doc.data().displayName });
        db.collection("recipes")
          .doc(doc.id)
          .collection("instructions")
          .orderBy("sortId", "asc")
          .onSnapshot(function (querySnapshot) {
            querySnapshot.docChanges().forEach(function (change) {
              let tempInstruction = {
                img: change.doc.data().stepImgUrl,
                desc: change.doc.data().desc,
              };
              if (change.type === "added") {
                instructions.push(tempInstruction);
                that.setState({ instructions });
              }
              if (change.type === "modified") {
              }
              if (change.type === "removed") {
              }
            });
            that.setState({ loading: false });
          });
        db.collection("recipes")
          .doc(doc.id)
          .collection("ingredients")
          .orderBy("createdDate", "asc")
          .onSnapshot(function (querySnapshot) {
            querySnapshot.docChanges().forEach(function (change) {
              let tempIngredients = {
                displayName: change.doc.data().displayName,
                isPantry: change.doc.data().desc,
                amount: change.doc.data().amount,
                uom: change.doc.data().uom,
                imgUrl: change.doc.data().imgUrl,
                type: change.doc.data().type,
              };
              if (change.type === "added") {
                ingredients.push(tempIngredients);
                var shoppingListItem =
                  change.doc.data().amount.toString() +
                  " " +
                  change.doc.data().uom +
                  " " +
                  change.doc.data().displayName;
                shoppingList.push(shoppingListItem);
                that.setState({ shoppingList, ingredients });
              }
              if (change.type === "modified") {
              }
              if (change.type === "removed") {
              }
            });
          });
      });
  }

  signUpNow = () => {
    logEvent("SignUpInitiatedRecipe", { name: this.props.match.params.id });
    const { history } = this.props;
    history.push(`/sign-up/${this.props.match.params.id}/signupFromMenu`);
  };

  /*global whisk*/
  addToList = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      var that = this;
      whisk.queue.push(function () {
        whisk.shoppingList.addProductsToList({
          products: that.state.shoppingList,
        });
      });
      logEvent("addedToList", { name: this.props.match.params.id });
    } else {
      logEvent("noSubAddedToList", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/addList`);
    }
  };

  signUpNow = () => {
    logEvent("SignInitiatedFromMenu", { name: this.props.match.params.id });
    const { history } = this.props;
    history.push(`/sign-up/${this.props.match.params.id}/signupFromMenu`);
  };

  viewList = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      whisk.queue.push(function () {
        whisk.shoppingList.viewList({
          styles: {
            type: "modal",
          },
        });
      });
      logEvent("viewedList", { name: this.props.match.params.id });
    } else {
      logEvent("noSubViewedList", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/viewList`);
    }
  };

  addToBasket = () => {
    // var isLoggedIn = false;
    // if (this.context.authUser) {
    //   this.context.authUser.isAnonymous
    //     ? (isLoggedIn = false)
    //     : (isLoggedIn = true);
    // }
    if (this.context.paidUser) {
      var that = this;
      whisk.queue.push(function () {
        whisk.shoppingList.addProductsToBasket({
          products: that.state.shoppingList,
        });
      });
      logEvent("addedToCart", { name: this.props.match.params.id });
    } else {
      logEvent("noSubAddedToCart", { name: this.props.match.params.id });
      const { history } = this.props;
      history.push(`/sign-up/${this.props.match.params.id}/addCart`);
    }
  };

  render() {
    var isLoggedIn = false;
    if (this.context.authUser) {
      this.context.authUser.isAnonymous
        ? (isLoggedIn = false)
        : (isLoggedIn = true);
    }
    let bannerImg = {
      backgroundImage: "url(" + this.state.recipeData.mainPicUrl + ")",
      backgroundSize: "cover",
      marginLeft: `auto`,
      marginRight: `auto`,
    };
    var unlock = false;
    var blurContent = "blur-content";
    if (this.context.paidUser) {
      unlock = true;
      blurContent = "";
    }
    return (
      <>
        <Helmet>
          {this.state.recipeName ? (
            <title>{this.state.recipeName + " | Popdega"}</title>
          ) : (
            <title>{"Chef designed meal plans | Popdega"}</title>
          )}
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <div itemScope itemType="https://schema.org/Recipe">
            <section id="recipe-header">
              <Row className="justify-content-center text-center ">
                <Col lg="12" className="justify-content-center">
                  <div
                    itemProp="image"
                    style={bannerImg}
                    alt={this.state.recipeName}
                    className="banner-recipe"
                  ></div>
                </Col>
              </Row>
              <Container>
                <div className="row justify-content-center text-center pt-5">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h1 itemProp="name">{this.state.recipeData.displayName}</h1>
                    <span hidden itemProp="author">
                      Popdega
                    </span>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <Container>
                      <div className="row align-middle">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                          <h6 itemProp="cookTime">
                            <i className="fa fa-clock-o pr-2" />
                            {this.state.recipeData.duration} minutes
                          </h6>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                          <h6>
                            <i className="fa fa-cutlery pr-2" />
                            {this.state.recipeData.serving} Servings
                          </h6>
                        </div>
                        <div
                          className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                          itemProp="nutrition"
                          itemScope
                          itemType="https://schema.org/NutritionInformation"
                        >
                          <h6 itemProp="calories">
                            <i className="fa fa-leaf pr-2" />{" "}
                            {this.state.recipeData.calories} calories
                          </h6>
                        </div>
                      </div>
                      <div className="row align-middle pt-4">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="pr-4">
                            Share this recpie!
                            <FacebookShareButton
                              url={window.location.href}
                              quote={this.state.recipeName + " | Popdega"}
                              hashtag="#popdega"
                              className="pl-2"
                            >
                              <FacebookIcon size={32} round={true} />{" "}
                            </FacebookShareButton>
                            <EmailShareButton
                              subject={this.state.recipeName + " | Popdega"}
                              body={
                                "Check out " +
                                this.state.recipeData.displayName +
                                " recipe from Popdega!\n Visit: " +
                                window.location.href
                              }
                              className="pl-2"
                            >
                              <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                            <WhatsappShareButton
                              url={window.location.href}
                              title={this.state.recipeName + " | Popdega"}
                              separator=":: "
                              className="pl-2"
                            >
                              <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                            <TwitterShareButton
                              url={window.location.href}
                              title={this.state.recipeName + " | Popdega"}
                              hashtag="#popdega"
                              className="pl-2"
                            >
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                          </h6>
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </Container>
            </section>
            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <section className="mb-5">
              <div className="btn-wrapper text-center ">
                <div className="container">
                  <div className="row  justify-content-center">
                    <div className="col-10 col-lg-4 mt-3">
                      <div className="text-left">
                        <h6>Buy ingredients online</h6>
                      </div>
                      <div className="text-muted text-left mb-3">
                        <small>
                          Select your retailer and purchase right now.
                        </small>
                      </div>
                      <div className="text-center">
                        <Button
                          // className="btn-icon success mb-3 mb-sm-0 "
                          block
                          color="success"
                          onClick={this.addToBasket}
                          id="whisk-basket"
                          className="btn-icon btn-3"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-bag-17" />
                          </span>
                          <span className="btn-inner--text">
                            Add recipe to cart
                          </span>
                        </Button>
                      </div>
                    </div>
                    <div className="col-4 col-lg-1 text-center mt-3 align-self-center d-flex justify-content-center">
                      <div className="text-muted text-center mb-3">
                        <small>OR</small>
                      </div>
                    </div>
                    <div className="col-10 col-lg-4 mt-3">
                      <div className="text-left">
                        <h6>
                          Create a shopping list
                          <NavLink
                            href="#"
                            onClick={this.viewList}
                            style={{ float: "right", color: "#2dce89" }}
                          >
                            <b>
                              <u>View list</u>
                            </b>
                          </NavLink>
                        </h6>
                      </div>
                      <div className="text-muted text-left mb-3">
                        <small>
                          Print, email, or view list on your mobile.
                        </small>
                      </div>
                      <div className="text-center">
                        <Button
                          // className="btn-icon success mb-3 mb-sm-0 "
                          // id="whisk-basket"
                          onClick={this.addToList}
                          block
                          color="success"
                          outline
                          className="btn-icon btn-3"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-bullet-list-67" />
                          </span>
                          <span className="btn-inner--text">
                            Add to shopping list
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-wrapper text-center mb-5">
                <div className="container">
                  <div className="row  justify-content-center">
                    <div className="col-10 col-lg-4 mt-3">
                      <div className="text-center">
                        <small>
                          Powered by{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.whisk.com"
                          >
                            Whisk
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="recipe-ingredients" className="section-grey mt-5">
              <Container>
                <Row className="justify-content-center text-center pt-3 ">
                  <div className="col-6">
                    <h4>Ingredients</h4>
                  </div>
                </Row>
                <Container>
                  <div className="row pt-3 pb-3">
                    {/* <div className="row">
                    {this.state.ingredients.map((item) => {
                        return (
                          <Ingredients
                            displayName={item.displayName}
                            picUrl={item.imgUrl}
                            amount={item.amount}
                            uom={item.uom}
                            type={item.type}
                          />
                        );
                      })} */}

                    <div className={`row ${blurContent}`}>
                      {this.state.ingredients.map((item) => {
                        return (
                          <Ingredients
                            displayName={item.displayName}
                            picUrl={item.imgUrl}
                            amount={item.amount}
                            uom={item.uom}
                            type={item.type}
                          />
                        );
                      })}
                      {unlock ? null : this.state.loading ? null : (
                        <Button
                          color="theme"
                          size="lg"
                          className="btn-icon btn-3 button-overlay"
                          // href={`/sign-up/${this.props.match.params.id}/signupFromMenu`}
                          onClick={() => this.signUpNow()}
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-lock-circle-open" />
                          </span>
                          Start free trial
                        </Button>
                      )}
                    </div>
                  </div>
                </Container>
                {this.state.recipeData.usesLeftOver ? (
                  <>
                    <hr
                      style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                    />
                    <section id="recipe-leftover">
                      <LeftOvers
                        lefOver={this.state.recipeData.leftOver}
                        leftOverDesc={this.state.recipeData.leftOverDesc}
                        history={this.props.history}
                      ></LeftOvers>
                    </section>
                  </>
                ) : null}
              </Container>
            </section>

            {/* <section id="recipe-leftover">
              <Container>
                <Row className="justify-content-center text-center pt-3">
                  <div className="col-6">
                    <h4>Leftovers </h4>
                  </div>
                </Row>
              </Container>
            </section> */}
            <section
              id="recipe-instructions"
              className="mt-5 instructions-section"
            >
              <Row className="justify-content-center text-center ">
                <div className="col-6">
                  <h4>Instructions</h4>
                </div>
              </Row>
              <Container>
                <div className="row pt-3 pb-3">
                  {/* <div className="row">
                    {this.state.instructions.map((item) => {
                      return <Instructions img={item.img} desc={item.desc} />;
                    })}
                  </div> */}
                  <div className={`row ${blurContent}`}>
                    {this.state.instructions.map((item) => {
                      return <Instructions img={item.img} desc={item.desc} />;
                    })}
                    {unlock ? null : this.state.loading ? null : (
                      <Button
                        color="theme"
                        size="lg"
                        className="btn-icon btn-3 button-overlay"
                        // href={`/sign-up/${this.props.match.params.id}/signupFromMenu`}
                        onClick={() => this.signUpNow()}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-lock-circle-open" />
                        </span>
                        Start free trial
                      </Button>
                    )}
                  </div>
                </div>
              </Container>
            </section>
          </div>
        </main>
        <MainFooter />
      </>
    );
  }
}

Recipe.contextType = UserContext;
export default withRouter(Recipe);
