import React from "react";
import { Container, Row, Col } from "reactstrap";

import Helmet from "react-helmet";
import MainFooter from "components/Footers/MainFooter.js";
import MainNavbar from "components/Navbars/MainNavbar.js";

class Privacy extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Privacy | Popdega</title>
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <Container>
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <p>
                  <br />
                </p>
                <p>
                  <br />
                </p>
                <h1>Popdega LLC Privacy Policy</h1>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className=" justify-content-center">
              <Col lg="10">
                <p>
                  <br />
                </p>
                <p>
                  This Privacy Policy describes how your personal information is
                  collected, used, and shared when you visit or make a purchase
                  from www.popdega.com (the &ldquo;Site&rdquo;).
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>PERSONAL INFORMATION WE COLLECT</strong>
                </p>
                <p>
                  When you visit the Site, we automatically collect certain
                  information about your device, including information about
                  your web browser, IP address, time zone, and some of the
                  cookies that are installed on your device. Additionally, as
                  you browse the Site, we collect information about the
                  individual web pages or products that you view, what websites
                  or search terms referred you to the Site, and information
                  about how you interact with the Site. We refer to this
                  automatically-collected information as &ldquo;Device
                  Information.&rdquo;
                </p>
                <p>
                  <br />
                </p>
                <p>
                  We collect Device Information using the following
                  technologies:
                </p>
                <p>
                  &nbsp; &nbsp; - &ldquo;Cookies&rdquo; are data files that are
                  placed on your device or computer and often include an
                  anonymous unique identifier. For more information about
                  cookies, and how to disable cookies, visit
                  http://www.allaboutcookies.org.
                </p>
                <p>
                  &nbsp; &nbsp; - &ldquo;Log files&rdquo; track actions
                  occurring on the Site, and collect data including your IP
                  address, browser type, Internet service provider,
                  referring/exit pages, and date/time stamps.
                </p>
                <p>
                  &nbsp; &nbsp; - &ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo;
                  and &ldquo;pixels&rdquo; are electronic files used to record
                  information about how you browse the Site.
                </p>
                <p>
                  Additionally when you make a purchase or attempt to make a
                  purchase through the Site, we collect certain information from
                  you, including your name, billing address, shipping address,
                  payment information (including credit card numbers, email
                  address, and phone number. &nbsp;We refer to this information
                  as &ldquo;Order Information.&rdquo;
                </p>
                <p>
                  When we talk about &ldquo;Personal Information&rdquo; in this
                  Privacy Policy, we are talking both about Device Information
                  and Order Information.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>HOW DO WE USE YOUR PERSONAL INFORMATION?</strong>
                </p>
                <p>
                  We use the Account Information that we collect generally to:
                </p>
                <p>Communicate with you;</p>
                <p>
                  When in line with the preferences you have shared with us,
                  provide you with information or advertising relating to our
                  products or services.
                </p>
                <p>
                  We use the Device Information that we collect to help us
                  screen for potential risk and fraud (in particular, your IP
                  address), and more generally to improve and optimize our Site
                  (for example, by generating analytics about how our customers
                  browse and interact with the Site, and to assess the success
                  of our marketing and advertising campaigns).
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>SHARING YOUR PERSONAL INFORMATION</strong>
                </p>
                <p>
                  We share your Personal Information with third parties to help
                  us use your Personal Information, as described above.
                  &nbsp;For example, we use Shopify to power our online
                  store--you can read more about how Shopify uses your Personal
                  Information here: &nbsp;https://www.shopify.com/legal/privacy.
                  &nbsp;We also use Google Analytics to help us understand how
                  our customers use the Site--you can read more about how Google
                  uses your Personal Information here:
                  &nbsp;https://www.google.com/intl/en/policies/privacy/.
                  &nbsp;You can also opt-out of Google Analytics here:
                  &nbsp;https://tools.google.com/dlpage/gaoptout.
                </p>
                <p>
                  Finally, we may also share your Personal Information to comply
                  with applicable laws and regulations, to respond to a
                  subpoena, search warrant or other lawful request for
                  information we receive, or to otherwise protect our rights.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>BEHAVIOURAL ADVERTISING</strong>
                </p>
                <p>
                  As described above, we use your Personal Information to
                  provide you with targeted advertisements or marketing
                  communications we believe may be of interest to you. &nbsp;For
                  more information about how targeted advertising works, you can
                  visit the Network Advertising Initiative&rsquo;s
                  (&ldquo;NAI&rdquo;) educational page at
                  http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                </p>
                <p>
                  <br />
                </p>
                <p>You can opt out of targeted advertising by:</p>
                <p>
                  &nbsp;GOOGLE - https://www.google.com/settings/ads/anonymous
                </p>
                <p>FACEBOOK - https://www.facebook.com/settings/?tab=ads</p>
                <p>
                  <br />
                </p>
                <p>
                  Additionally, you can opt out of some of these services by
                  visiting the Digital Advertising Alliance&rsquo;s opt-out
                  portal at: &nbsp;http://optout.aboutads.info/.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>DO NOT TRACK</strong>
                </p>
                <p>
                  Please note that we do not alter our Site&rsquo;s data
                  collection and use practices when we see a Do Not Track signal
                  from your browser.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>YOUR RIGHTS</strong>
                </p>
                <p>
                  If you are a European resident, you have the right to access
                  personal information we hold about you and to ask that your
                  personal information be corrected, updated, or deleted. If you
                  would like to exercise this right, please contact us through
                  the contact information below.
                </p>
                <p>
                  Additionally, if you are a European resident we note that we
                  are processing your information in order to fulfill contracts
                  we might have with you (for example if you make an order
                  through the Site), or otherwise to pursue our legitimate
                  business interests listed above. &nbsp;Additionally, please
                  note that your information will be transferred outside of
                  Europe, including to Canada and the United States.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>DATA RETENTION</strong>
                </p>
                <p>
                  When you place an order through the Site, we will maintain
                  your Order Information for our records unless and until you
                  ask us to delete this information.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>CHANGES</strong>
                </p>
                <p>
                  We may update this privacy policy from time to time in order
                  to reflect, for example, changes to our practices or for other
                  operational, legal or regulatory reasons.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <strong>CONTACT US</strong>
                </p>
                <p>
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact us by e-mail at{" "}
                  <a href="mailto: info@popdega.com"> info@popdega.com</a> or by
                  mail using the details provided below:
                </p>
                <p>
                  &nbsp; 4241 Columbia Pike, Apt 503, Arlington, VA, 22204,
                  United States
                </p>
              </Col>
            </Row>
          </Container>
        </main>
        <MainFooter />
      </>
    );
  }
}

export default Privacy;
