import React from "react";

import MainNavbar from "../components/Navbars/MainNavbar.js";
import MainFooter from "../components/Footers/MainFooter.js";
import FaqComponent from "components/FaqComponent.js";

class FaqPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <MainNavbar />
        <main ref="main">
          <FaqComponent />
        </main>
        <MainFooter />
      </>
    );
  }
}

export default FaqPage;
