import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";

import MainNavbar from "components/Navbars/MainNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";
import MealPlan from "components/MealPlan.js";
import SubscribeNewsletter from "components/HomePage/SubscribeNewsletter.js";
import "@material/react-chips/dist/chips.css";
import { ChipSet, Chip } from "@material/react-chips";
import Helmet from "react-helmet";

import { db } from "../firebase/firebase.js";

class Menus extends React.Component {
  state = {
    tags: [],
    selectedChipIds: [],
    menus: [],
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    let tags = [...this.state.tags];
    var that = this;
    db.collection("menuTags")
      .where("isArchived", "==", false)
      .orderBy("sortId", "asc")
      .onSnapshot(
        function (querySnapshot) {
          querySnapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              tags.push({
                key: change.doc.id,
                value: change.doc.data().displayName,
              });
            }
            if (change.type === "modified") {
            }
            if (change.type === "removed") {
              tags = that.state.tags.filter(
                (item) => item.key !== change.doc.id
              );
            }
          });
          that.setState({ tags });
          // that.setState({ selectedChipIds: selected });
        },
        function (error) {
          console.log("Opps, we got an error", error);
        }
      );
    let menus = [];

    // db.collection("menus")
    //   .where("isArchived", "==", false)
    //   .orderBy("createdDate", "desc")
    //   .get()
    //   .then(function (querySnapshot) {
    //     querySnapshot.forEach(function (doc) {
    //       let id = doc.id;
    //       let menuTags = [];
    //       doc.data().tags.forEach(function (ref) {
    //         ref.get().then((doc) => {
    //           menuTags.push({ key: doc.id, value: doc.data().displayName });
    //         });
    //       });
    //       menus.push({ key: id, value: menuTags });
    //     });
    //   });

    db.collection("menus")
      .where("isArchived", "==", false)
      .orderBy("createdDate", "desc")
      .onSnapshot(
        function (querySnapshot) {
          querySnapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              let id = change.doc.id;
              let menuTags = [];
              change.doc.data().tags.forEach(function (ref) {
                ref.get().then((doc) => {
                  menuTags.push({ key: doc.id, value: doc.data().displayName });
                });
              });
              menus.push({ key: id, value: menuTags });
            }
            if (change.type === "modified") {
            }
            if (change.type === "removed") {
            }
          });
          that.setState({ menus }, () => {
            if (that.props.match.params.tag) {
              let selected = [...that.state.selectedChipIds];
              selected.push(that.props.match.params.tag);
              that.setState({ selectedChipIds: selected });
            }
          });
        },
        function (error) {
          console.log("Opps, we got an error", error);
        }
      );
  }
  render() {
    const that = this;
    return (
      <>
        <Helmet>
          <title>Zero Waste Meal Plans | Popdega</title>
          <meta
            name="description"
            content="Chef-designed meal plans for variety of dietary preferences such as, Vegetarian, Vegan and Balanced Diet."
          />
        </Helmet>
        <MainNavbar />
        <main ref="main">
          <section className="menus-banner">
            <Container>
              <Row className="justify-content-center text-center section-heading ">
                <Col lg="12">
                  <h1 className="display-3" style={{ color: "white" }}>
                    Choose a Meal Plan
                  </h1>
                </Col>
              </Row>
            </Container>
          </section>
          <Row className="justify-content-center py-5">
            <Col lg="6">
              <ChipSet
                className="justify-content-center "
                filter
                selectedChipIds={this.state.selectedChipIds}
                handleSelect={(selectedChipIds) =>
                  this.setState({ selectedChipIds })
                }
              >
                {this.state.tags.map((item) => (
                  <Chip id={item.key} label={item.value} />
                ))}
              </ChipSet>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col lg="8">
              {this.state.menus.map(function (menu) {
                let checkTags = false;
                if (that.state.selectedChipIds.length === 0) {
                  checkTags = true;
                } else {
                  for (let i = 0; i < that.state.selectedChipIds.length; i++) {
                    for (let a = 0; a < menu.value.length; a++) {
                      if (menu.value[a].key === that.state.selectedChipIds[i]) {
                        checkTags = true;
                        break;
                      }
                    }
                    if (checkTags) break;
                  }
                }
                return checkTags ? (
                  <MealPlan mealId={menu.key} isEmpty={false} />
                ) : (
                  <MealPlan mealId={menu.key} isEmpty={true} />
                );
              })}
            </Col>
          </Row>
          <section className="section-grey py-4">
            <div className="container ">
              <Row className="justify-content-center">
                <Col lg="6" xs="12">
                  <div className="menu-page-wasteBanner"></div>
                </Col>
                <Col lg="6" xs="12">
                  <div className="section-heading">
                    <h4 className="display-3 text-center">
                      Cook without waste!
                    </h4>
                    <p className="pt-3">
                      With our carefully designed meal plans, our chefs make
                      sure all perishable ingredients are used to the fullest to
                      avoid food waste.
                    </p>
                    <p>
                      If you still have questions visit our{" "}
                      <Link to="/faq">
                        {" "}
                        <a>FAQ</a>{" "}
                      </Link>
                      or visit our{" "}
                      <Link to="/contact-us">
                        <a>Contact Us</a>
                      </Link>
                      page to drop us a line.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          <SubscribeNewsletter id="subscribeNewsletter" title="New Meal Plans Every Week" text="Subscribe to our news letter to get notified when we release new recipes."/>
          <section className="section-grey py-4">
            <div className="container ">
              <Row className="justify-content-center">
                <Col lg="6" xs="12">
                  <div className="section-heading">
                    <h4 className="display-3 text-center">
                      Save time by using leftovers
                    </h4>
                    <p className="pt-3">
                      Cooking can be time-consuming. But it doesn't have to be!
                      Our meal plans utilize leftovers from one recipe to use in
                      other recipes within the meal plan. Hence saving you time
                      without a compromise.
                    </p>
                  </div>
                </Col>
                <Col lg="6" xs="12">
                  <img
                    alt="Use left overs and save time"
                    className="img-fluid rounded-xl shadow-lg "
                    src={require("assets/img/banners/leftOver.png")}
                  ></img>
                  {/* <div className="menu-page-saveTime"></div> */}
                </Col>
              </Row>
            </div>
          </section>
        </main>
        <MainFooter />
      </>
    );
  }
}

export default withRouter(Menus);
