import React from "react";
import classnames from "classnames";
import { db, newSubscriberEmail } from "../../firebase/firebase.js";
import {
  Button,
  FormGroup,
  Container,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

class SubscribeNewsletter extends React.Component {
  state = { showInfo: false, showSuccess: false, showError: false };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    var email = event.target.email.value;
    var ref = db.collection("newsletter").doc(email);
    var that = this;
    ref.get().then((doc) => {
      if (!doc.exists) {
        ref
          .set({
            email: email,
            isActive: true,
          })
          .then(function (docRef) {
            that.setState({ showSuccess: true });
            that.setState({ showError: false });
            newSubscriberEmail(email);
          })
          .catch(function (error) {
            console.log(error);
            that.setState({ showError: true });
            that.setState({ showSuccess: false });
          });
      } else {
        that.setState({ showSuccess: true });
      }
    });
  }

  render() {
    return (
      <>
        <Container className="py-lg-md d-flex justify-content-center pb-4">
          <Row className="justify-content-center text-center">
            <Col lg="12">
              <div className="section-heading-top pb-4 pt-0">
                {this.props.title ? (
                  <h2 className="display-3 text-center">{this.props.title}</h2>
                ) : (
                  <h2 className="display-3 text-center">Stay Updated</h2>
                )}
                {this.props.text ? (
                  <p className="text-center">{this.props.text}</p>
                ) : (
                  <p className="text-center">
                    Get notified when we release new recipes and product
                    updates!
                  </p>
                )}
              </div>
              <Form role="form" className="pb-4" onSubmit={this.handleSubmit}>
                <FormGroup
                  className={classnames("mb-3", {
                    focused: this.state.emailFocused,
                  })}
                >
                  <div className="row justify-content-center">
                    <div className="col-10 col-lg-8 col-xl-8 col-md-8">
                      <InputGroup className="input-group-alternative ">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          placeholder="Email"
                          type="email"
                          name="email"
                          onFocus={(e) => this.setState({ emailFocused: true })}
                          onBlur={(e) => this.setState({ emailFocused: false })}
                        />
                        <div className="text-center">
                          <Button className="" color="success" type="submit">
                            Submit
                          </Button>
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        <UncontrolledAlert
          color="success"
          fade={true}
          style={{
            display: this.state.showSuccess ? "block" : "none",
          }}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            You've successfuly subscribed!
          </span>
        </UncontrolledAlert>
        <UncontrolledAlert
          color="danger"
          fade={true}
          style={{
            display: this.state.showError ? "block" : "none",
          }}
        >
          <span className="alert-inner--text">
            <strong>Oops!</strong> Something went wrong. Please contact us.
          </span>
        </UncontrolledAlert>
      </>
    );
  }
}

export default SubscribeNewsletter;
